import { useState } from "react";
import ExecuteModal from "../ExecuteModal";
import EvalutorComponent from "../ExecutionEvaluatorArgs";
import ServerDetailsModal from "../ServerDetailsModal";

const Switch = ({ project, onClose }: any) => {
  const [index, setIndex] = useState<number>(0);
  const [payload, setPayload] = useState<any>(null); 

  return (
    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50 p-4 w-screen">
      <div className="bg-white rounded-xl shadow-lg w-full max-w-2xl max-h-[90vh] flex flex-col">
        {{
          0: (
            <ServerDetailsModal
              project={project}
              onClose={onClose}
              setIndex={setIndex}
            />
          ),
          1: (
            <ExecuteModal
              project={project}
              onClose={onClose}
              setIndex={setIndex}
              setPayload={setPayload}
            />
          ),
          2: (
            <EvalutorComponent
              project={project}
              onClose={onClose}
              setIndex={setIndex}
              payload={payload} 
            />
          ),
        }[index]}
      </div>
    </div>
  );
};

export default Switch;
