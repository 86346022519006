import { AxiosError } from "axios";
import { Loader, Server, X } from "lucide-react";
import React, { useState } from "react";
import { toast } from "sonner";
import backendAPI from "../../../services/apiRequestService";
import FileUpload from "./components/FileUpload";
import InputField from "./components/InputField";

export interface ServerModalProps {
  onClose: () => void;
  onRegister: any;
}

const ServerModal: React.FC<ServerModalProps> = ({ onClose, onRegister }) => {
  const [host, setHost] = useState<string>("");
  const [port, setPort] = useState<string>("");
  const [file, setFile] = useState<File | null>(null);
  const [userName, setUserName] = useState<string>("");
  const [serverName, setServerName] = useState<string>("");
  const [executablePath, setExecutablePath] = useState<string>("");
  const [isSubmiting, setIsSubmiting] = useState<boolean>(false);

  const handleSubmit = async (
    e: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault();
    setIsSubmiting(true);

    try {
      const serverPayload = {
        host,
        port: parseInt(port),
        userName,
        serverName,
        executablePath,
      };
      if (!file) {
        toast.error("need file for registration.");
        return;
      }
      const serverResponse = await backendAPI.post(
        "/project-management/servers",
        serverPayload
      );

      const serverId = serverResponse.data.id;

      const formData = new FormData();
      formData.append("privateKey", file);

      await backendAPI.post(
        `/project-management/servers/${serverId}`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      toast.success("Server registered successfully.");
      onRegister(serverResponse.data);
    } catch (error) {
      if (error instanceof AxiosError && error.response) {
        toast.error(
          error.response?.data?.message || "Unable to register server."
        );
      } else {
        toast.error("An unknown error occurred.");
      }
    } finally {
      setIsSubmiting(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex items-center justify-center z-50 p-4">
      <div className="bg-white bg-opacity-95 rounded-lg p-6 w-full max-w-2xl">
        <div className="pb-2 mb-2 border-b flex justify-between items-center">
          <h2 className="text-lg font-medium">Register Server</h2>
          <button
            onClick={onClose}
            className="p-1 hover:bg-gray-100 rounded-full transition-colors"
            aria-label="Close"
          >
            <X className="h-5 w-5 text-gray-500" />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="space-y-4 pt-2">
          <InputField
            id="serverName"
            label="Name"
            value={serverName}
            onChange={(e) => setServerName(e.target.value)}
            required
          />
          <div className=" flex justify-between">
            <InputField
              id="host"
              label="Host"
              value={host}
              onChange={(e) => setHost(e.target.value)}
              required
              styleOuter=" flex-1 mr-2"
            />
            <p className=" flex items-end mb-2 mr-2">:</p>
            <InputField
              id="port"
              label="Port"
              value={port}
              onChange={(e) => setPort(e.target.value)}
              required
            />
          </div>
          <InputField
            id="userName"
            label="Username"
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
            required
          />
          <InputField
            id="executablePath"
            label="ExecutablePath"
            value={executablePath}
            onChange={(e) => setExecutablePath(e.target.value)}
            required
          />

          <div className="mb-2">
            <label className="block font-medium mb-1">Private Key File</label>
            <FileUpload onFileChange={setFile} />
          </div>
          <div className="pt-2 mt-2 border-t flex justify-end">
            <button
              type="submit"
              disabled={isSubmiting}
              className={` ${
                isSubmiting ? "opacity-50 cursor-not-allowed" : ""
              } px-3 items-center py-1.5 flex gap-3 rounded-md bg-teal-600 text-white hover:bg-teal-700`}
            >
              {isSubmiting ? (
                <Loader size={20} className="animate-spin" />
              ) : (
                <Server size={20} />
              )}
              <p>Register Server</p>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ServerModal;
