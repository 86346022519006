import { AxiosError } from "axios";
import React, { act, useEffect, useState } from "react";
import { toast, Toaster } from "sonner";
import backendAPI from "../../../services/apiRequestService";
import Table from "../../common/Table";
import ViewServerDetails from "./components/ViewServerDetails";
import ServerModal from "./ServerModal";
import { useAppSelector } from "../../../redux/hooks";
import ThreeDot from "../../common/ThreeDot";

interface Server {
  id: string;
  host: string;
  port: number;
  userName: string;
  status: string;
  serverName: string;
}

const ServerTable: React.FC = () => {
  const [servers, setServers] = useState<Server[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedServer, setSelectedServer] = useState<Server | null>(null);
  const [dropdownOpen, setDropdownOpen] = useState<number | null>(null);
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [deletingServer, setDeletingServer] = useState<Server | null>(null);

  const activeTenantId = useAppSelector((state) => state.tenant.active);

  const menuState = {
      visible: { view: true, delete:true }
    };

    const handleAction = (action: any) => {
      switch(action.option){
        case "view" :
          handleView(action.element)
        break;
        case "delete" :
          handleDeleteClick(action.element)
        break;
        default:
          toast.info(`${action.option}: Option not available`)
      }
  
    };

  const fetchServers = async (): Promise<void> => {
    try {
      const response = await backendAPI.get("/project-management/servers");
      setServers(response.data);
    } catch (error) {
      setServers([]);
      if (error instanceof AxiosError && error.response) {
        toast.error(
          error.response?.data?.error ||
            "An error occurred while fetching data."
        );
      } else {
        toast.error("An unknown error occurred. Please try again later.");
      }
    }
  };

  useEffect(() => {
    fetchServers();
  }, [activeTenantId]);

  const handleAddServer = (): void => {
    setShowModal(true);
  };

  const onRegister = async (servers: Server): Promise<void> => {
      setShowModal(false);
      setServers((prevServers) => [...prevServers, servers]);
      // fetchServers();
  };

  const handleView = (server: Server): void => {
    setSelectedServer(server);
    setDropdownOpen(null);
  };

  const handleDeleteClick = (server: Server): void => {
    setDeletingServer(server);
    setShowConfirmation(true);
  };

  const handleDelete = async (): Promise<void> => {
    if (!deletingServer) return;

    try {
      await backendAPI.delete(
        `/project-management/servers/${deletingServer.id}`
      );
      setServers((prevServers) =>
        prevServers.filter((server) => server.id !== deletingServer.id)
      );
      toast.success("Server deleted successfully.");
    } catch (error) {
      if (error instanceof AxiosError && error.response) {
        toast.error(
          error.response?.data?.message || "Error deleting the Server"
        );
      } else {
        toast.error("An unknown error occurred while deleting the server.");
      }
    } finally {
      setShowConfirmation(false);
      setDeletingServer(null);
    }
  };

  const handleCancelDelete = (): void => {
    setShowConfirmation(false);
    setDeletingServer(null);
  };

  const columns = React.useMemo(
    () => [
      {
        header: "ServerName",
        accessor: "serverName" as const,
      },
      {
        header: "Host",
        accessor: "host" as const,
      },
      {
        header: "Port",
        accessor: "port" as const,
      },
      {
        header: "UserName",
        accessor: "userName" as const,
      },
      {
        header: "Actions",
        accessor: "actions" as const,
        render: (server: Server, index: number) => (
          <div className=" flex justify-end items-center">
            <ThreeDot
              visible={menuState.visible}
              onAction={handleAction}
              on={server}
              isOpen={dropdownOpen === index}
              toggleDropdown={(event:any) => {
                if (event === null) {
                  setDropdownOpen(null);
                  return;
                }
                event.stopPropagation();
                setDropdownOpen(dropdownOpen === index ? null : index);
              }}
            />
          </div>
        ),
      },
    ],
    [dropdownOpen, handleView, handleDeleteClick]
  );

  return (
    <div className="pt-4 shadow-inner">
      <Toaster richColors />
      <div className="flex justify-between items-center p-4">
        <h2 className="text-lg font-bold">
          Total Servers - {servers.length > 0 ? servers.length : "0"}
        </h2>
        <button
          className="bg-black text-white px-4 py-2 rounded-md flex items-center"
          onClick={handleAddServer}
        >
          + Add Server
        </button>
      </div>

      <Table
        data={servers}
        columns={columns}
        onRowClick={(item) => {
          setDropdownOpen(null);
        }}
        striped={true}
        className="w-full" // Optional: add any additional classes you need
      />

      {showModal && (
        <ServerModal
          onClose={() => setShowModal(false)}
          onRegister={onRegister}
        />
      )}

      {selectedServer && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <ViewServerDetails
            server={selectedServer}
            onClose={() => setSelectedServer(null)}
          />
        </div>
      )}

      {showConfirmation && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-30">
          <div className="bg-white p-6 rounded-md shadow-lg w-96">
            <div className="border-b pb-4 mb-4">
              <h3 className="text-lg font-semibold text-center">
                Are you sure you want to delete?
              </h3>
            </div>
            <div className="flex justify-around pt-4 mt-4">
              <button
                className="bg-teal-600 hover:bg-gray-600 text-white px-4 py-2 rounded-md"
                onClick={handleDelete}
              >
                Yes
              </button>
              <button
                className="bg-teal-600 hover:bg-gray-600 text-white px-4 py-2 rounded-md"
                onClick={handleCancelDelete}
              >
                No
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ServerTable;
