import { X } from "lucide-react";
import React from "react";

interface User {
  id: string;
  username: string;
  firstName: string;
  lastName: string;
  email: string;
  role: string;
}

interface ManageUsersDetailsProps {
  user: User;
  onClose: () => void;
}

const ManageUsersDetails: React.FC<ManageUsersDetailsProps> = ({
  user,
  onClose,
}) => {
  return (
    <div className="bg-white rounded-lg p-6 w-full max-w-2xl">
      <div className="bg-white pb-2 mb-4 border-b flex justify-between items-center">
        <h2 className="text-lg font-medium">User Details</h2>
        <button
          onClick={onClose}
          className="p-1 hover:bg-gray-100 rounded-full transition-colors"
          aria-label="Close"
        >
          <X className="h-5 w-5 text-gray-500" />
        </button>
      </div>

      <div className="space-y-4">
        <div className="grid grid-cols-2 gap-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Username
            </label>
            <div className="px-3 py-2 bg-gray-100 rounded-md">
              {user.username}
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Role
            </label>
            <div className="px-3 py-2 bg-gray-100 rounded-md">{user.role}</div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              First Name
            </label>
            <div className="px-3 py-2 bg-gray-100 rounded-md">
              {user.firstName}
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Last Name
            </label>
            <div className="px-3 py-2 bg-gray-100 rounded-md">
              {user.lastName}
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Email
            </label>
            <div className="px-3 py-2 bg-gray-100 rounded-md">{user.email}</div>
          </div>
        </div>

        <div className="border-t pt-4 mt-4">
          <label className="block text-sm font-medium text-gray-700 mb-1">
            User ID
          </label>
          <div className="px-3 py-2 bg-gray-100 rounded-md font-mono text-sm">
            {user.id}
          </div>
        </div>

        <div className="pt-4 mt-4 border-t flex justify-end">
          <button
            onClick={onClose}
            className="px-4 py-1.5 rounded-md bg-gray-300 hover:bg-gray-400"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default ManageUsersDetails;
