import React from "react";
import TableHead from "../layout/servers/components/TableHead";

type ColumnDef<T> = {
  header: string;
  accessor: keyof T | "actions";
  render?: (item: T, index: number) => React.ReactNode;
};

interface TableProps<T extends { id: string | number }> {
  data: T[];
  columns: ColumnDef<T>[];
  onRowClick?: (item: T) => void;
  striped?: boolean;
  className?: string;
  renderExpandedContent?: (item: T) => React.ReactNode;
  getRowClassName?: (item: T, index: number) => string;
}

const Table = <T extends { id: string | number }>({
  data,
  columns,
  onRowClick,
  striped = true,
  className = "",
  renderExpandedContent,
  getRowClassName,
}: TableProps<T>) => {
  return (
    <div className="relative">
      <table className="w-full border-collapse">
        <TableHead headers={columns.map((col) => col.header)} />
        <tbody>
          {data.length > 0 &&
            data.map((item, index) => (
              <React.Fragment key={item.id}>
                <tr
                  onClick={() => onRowClick?.(item)}
                  className={
                    getRowClassName?.(item, index) ||
                    `border-b hover:bg-gray-100 relative ${
                      index % 2 === 0 ? "bg-white" : "bg-gray-50"
                    }`
                  }
                >
                  {columns.map((column, colIndex) => (
                    <td
                      key={`${item.id}-${colIndex}`}
                      className="px-6 py-3"
                    >
                      {column.render
                        ? column.render(item, index)
                        : column.accessor !== "actions"
                          ? String(item[column.accessor])
                          : null}
                  </td>
                  ))}
                </tr>
                {renderExpandedContent?.(item)}
              </React.Fragment>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
