import { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import AppRoutes from "./routes/AppRoutes";
import { keyInit } from "./services/KeycloakService";

function App() {
  const [auth, setAuth] = useState(false);

  useEffect(() => {
    keyInit()
      .then((success) => {
        if (success) {
          setAuth(true);
        }
      })
      .catch((error) => {
        console.error("Key initialization failed", error);
      });
  }, []);

  return (
    <>
      {" "}
      {auth === true && (
        <BrowserRouter>
          <div className=" bg-white min-h-screen">
            <AppRoutes />
          </div>
        </BrowserRouter>
      )}
    </>
  );
}

export default App;
