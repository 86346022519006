import { AxiosError } from "axios";
import { Loader, ServerIcon, X } from "lucide-react";
import React, { useEffect, useState } from "react";
import { toast } from "sonner";
import { useAppSelector } from "../../../redux/hooks";
import backendAPI from "../../../services/apiRequestService";
import { Project } from "../../../types/project";

interface Server {
  id: string;
  host: string;
  port: number;
  userName: string;
  status: string;
  serverName: string;
  executablePath: string;
}

interface ServerDetailsModalProps {
  project: Project;
  setIndex: (arg0 :number) => void;
  onClose: () => void;
}

const ServerDetailsModal: React.FC<ServerDetailsModalProps> = ({
  project,
  setIndex,
  onClose,
}) => {

  const [servers, setServers] = useState<Server[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [
    selectedServer, setSelectedServer] = useState<Server | null>(null);
  const [selectedServerId, setSelectedServerId] = useState<string | null>(null);

  const activeTenantId = useAppSelector((state) => state.tenant.active);

  const fetchServers = async (): Promise<void> => {
    setLoading(true);
    try {
      const response = await backendAPI.get("/project-management/servers");
      setServers(response.data);
      setError(null);
    } catch (error) {
      setServers([]);
      setError(
        error instanceof AxiosError && error.response
          ? error.response?.data?.error ||
              "An error occurred while fetching data."
          : "An unknown error occurred. Please try again later."
      );
      toast.error(
        error instanceof AxiosError && error.response
          ? error.response.data.error ||
              "An error occurred while fetching data."
          : "An unknown error occurred. Please try again later."
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchServers();
  }, [activeTenantId]);

  const handleServerClick = (server: Server) => {
    setSelectedServer(server);
    setSelectedServerId(server.id);
    localStorage.setItem("selectedServer", server.id);
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex justify-center items-center z-50 p-4">
      <div className="bg-white bg-opacity-95 rounded-lg p-6 w-full max-w-xl">
        <div className="pb-2 mb-2 border-b flex justify-between items-center">
          <h2 className="text-lg font-medium">Execute {project.name}</h2>
          <button
            onClick={onClose}
            className="p-1 hover:bg-gray-100 rounded-full transition-colors"
            aria-label="Close"
          >
            <X className="h-5 w-5 text-gray-500" />
          </button>
        </div>
        <div className="pt-2 mb-4">
          <div className="flex items-center">
            <ServerIcon className="w-5 h-5 mr-2" />
            <h3 className="text-sm font-medium">Choose Your Server</h3>
          </div>
        </div>

        {loading ? (
          <div className="flex justify-center items-center">
            <Loader className="animate-spin text-blue-500" size={24} />
          </div>
        ) : error ? (
          <p className="text-red-500">{error}</p>
        ) : (
          <ul className="mb-4 space-y-2">
            {servers.map((server) => (
              <li
                key={server.id}
                onClick={() => handleServerClick(server)}
                className={`cursor-pointer p-4 border rounded-md transition-colors 
                  ${
                    selectedServer?.id === server.id
                      ? "bg-[#E8F0FE] border-blue-500"
                      : "border-gray-300 hover:border-blue-500 hover:bg-[#f0f8ff]"
                  }`}
              >
                <span className="font-bold">{server.serverName}</span> -{" "}
                {server.host}:{server.port}
              </li>
            ))}
          </ul>
        )}

        <div className="pt-2 mt-2 border-t flex justify-between">
          <button
            onClick={() => setIndex(1)}
            className="bg-teal-600 hover:bg-teal-700 text-white px-4 py-2 rounded-md mt-4 flex items-center gap-2"
          >
            <p>Next</p>
          </button>
        </div>
      </div>
      </div>
  );
};

export default ServerDetailsModal;
