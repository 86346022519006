import React, { useEffect, useState } from "react";
import { formatLanguage, formatStatus } from "../../common/Formatter";
import { Evaluator } from "../../../types/types";
import { getDateFromTimestamp } from "../../common/DateAndTime";
import Table from "../../common/Table";
import DownloadBtn from "../../common/button/DownloadBtn";
import backendAPI from "../../../services/apiRequestService";
import { toast } from "sonner";
import { X, Check } from "lucide-react";
import { AxiosError } from "axios";

const PendingEvaluator = () => {
  const [confirmationId, setConfirmationId] = useState<string | null>(null);
  const [pendingStatus, setPendingStatus] = useState<string | null>(null);
  const [updatedStatuses, setUpdatedStatuses] = useState<
    Record<string, string>
  >({});
  const [evaluators, setEvaluators]= useState<Evaluator[]>([]);
  const fetchEvaluators = async (): Promise<void> => {
    try {
      const response = await backendAPI.get(
        "/project-management/tenantEvaluators?status=CREATED"
      );
      setEvaluators(response.data);
    } catch (error) {
      setEvaluators([]);
      if (error instanceof AxiosError && error.response) {
        toast.error(
          error.response?.data?.error ||
            "An error occurred while fetching data."
        );
      } else {
        toast.error("An unknown error occurred. Please try again later.");
      }
    }
  };
  useEffect(()=>{
    fetchEvaluators();
  },[])

  const handleEvaluatorStatus = async (e: any, id: string) => {
    const status = e.target.value;
    try {
      const response = await backendAPI.patch(
        `/project-management/evaluators/${id}/changeStatus`,
        { status }
      );
      if (response.status === 200) {
        setUpdatedStatuses((prev) => ({
          ...prev,
          [id]: status,
        }));
        pendingStatus === "APPROVED" ? toast.success(`${response.data.name}: Approved Successfully`) 
        : toast.error(`${response.data.name}: Rejected Successfully`);
      }
      setConfirmationId(null);
      setPendingStatus(null);
    } catch (error) {
      e.target.value = "CREATED";
      toast.error("Update Failed");
    }
  };

  const ActionButtons = ({ evaluator }: { evaluator: Evaluator }) => {
    const currentStatus = updatedStatuses[evaluator.id] || evaluator.status;

    if (currentStatus === "APPROVED" || currentStatus === "REJECTED") {
      return (
        <div
          className={`px-3 py-1.5 rounded-md text-sm font-medium ${
            currentStatus === "APPROVED"
              ? "bg-green-100 text-green-700"
              : "bg-red-100 text-red-700"
          }`}
        >
          <div className="flex items-center gap-1">
            {currentStatus === "APPROVED" ? (
              <Check size={15} />
            ) : (
              <X size={15} />
            )}
            <span>
              {currentStatus === "APPROVED" ? "Approved" : "Rejected"}
            </span>
          </div>
        </div>
      );
    }

    if (confirmationId === evaluator.id) {
      return (
        <div className="flex items-center gap-3 w-full">
          <button
            onClick={(e) => {
              (e.target as any).value = pendingStatus;
              handleEvaluatorStatus(e, evaluator.id);
            }}
            className={`px-4 py-1.5 text-sm font-medium rounded-md transition-colors duration-200 flex items-center gap-1 ${
              pendingStatus === "APPROVED"
                ? "bg-green-600 hover:bg-green-700 text-white"
                : "bg-red-600 hover:bg-red-700 text-white"
            }`}
          >
            <span className="text-md">
              {pendingStatus === "APPROVED" ? (
                <Check size={15} />
              ) : (
                <X size={15} />
              )}
            </span>
            <span>
              Confirm {pendingStatus === "APPROVED" ? "Approve" : "Reject"}
            </span>
          </button>
          <button
            onClick={() => {
              setConfirmationId(null);
              setPendingStatus(null);
            }}
            className="px-4 py-1.5 text-sm font-medium bg-gray-100 text-gray-700 rounded-md hover:bg-gray-200 transition-colors duration-200"
          >
            Cancel
          </button>
        </div>
      );
    }

    return (
      <div className="flex items-center gap-3 w-full">
        <button
          onClick={() => {
            setConfirmationId(evaluator.id);
            setPendingStatus("APPROVED");
          }}
          className="px-4 py-1.5 bg-green-100 text-green-700 rounded-md hover:bg-green-200 transition-colors duration-200 font-medium text-sm flex items-center gap-1"
        >
          <Check size={15} />
          <span>Approve</span>
        </button>
        <button
          onClick={() => {
            setConfirmationId(evaluator.id);
            setPendingStatus("REJECTED");
          }}
          className="p-2 pl-3 bg-red-100 text-red-700 rounded-md hover:bg-red-200 transition-colors duration-200 font-medium text-sm flex items-center gap-1"
        >
          <X size={15} />
          <span></span>
        </button>
      </div>
    );
  };

  const columns = React.useMemo(
    () => [
      {
        header: "Name",
        accessor: "name" as const,
        render: (evaluator: Evaluator) => (
          <div className="truncate">{evaluator.name}</div>
        ),
      },
      {
        header: "Language",
        accessor: "language" as const,
        render: (evaluator: Evaluator) => (
          <div className="truncate">{formatLanguage(evaluator.language)}</div>
        ),
      },
      {
        header: "Date Created",
        accessor: "createdAt" as const,
        render: (evaluator: Evaluator) => (
          <div className="truncate">
            {getDateFromTimestamp(parseInt(evaluator.createdAt), true)}
          </div>
        ),
      },
      {
        header: "Actions",
        accessor: "actions" as const,
        render: (evaluator: Evaluator) => (
          <div className=" flex items-center justify-end gap-2">
            <div className="w-[250px] flex justify-end">
              <div className="flex justify-end">
                <ActionButtons evaluator={evaluator} />
              </div>
            </div>
            <DownloadBtn
              item={evaluator}
              endpoint={`/project-management/evaluators/${evaluator.id}/download`}
              style=" rounded-lg border shadow-md active:scale-95 text-xs w-fit hover:shadow:sm"
            />
          </div>
        ),
      },
    ],
    [confirmationId, pendingStatus, updatedStatuses]
  );

  return (
    <>
      <div className="mt-4">
        <h2 className="text-lg font-bold m-4 ">
          Pending Approval - {evaluators.length}
        </h2>
      </div>
      <div className="overflow-x-auto">
        <div className="table-fixed">
          <Table
            data={evaluators}
            columns={columns}
            className="w-full whitespace-nowrap"
          />
        </div>
      </div>
    </>
  );
};

export default PendingEvaluator;
