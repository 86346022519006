import React, { useState } from "react";
import { Info } from "lucide-react";
import { getDateFromTimestamp } from "../../common/DateAndTime";
import ThreeDot from "../../common/ThreeDot";
import Table from "../../common/Table";
import { toast } from "sonner";
import backendAPI from "../../../services/apiRequestService";
import { AxiosError } from "axios";

interface Execution {
  id: string;
  name: string;
  generations: number;
  status: string;
  dateCreated: string;
  statusReason: string;
}

const ExecutionDetails: React.FC<any> = ({ project, executions }) => {
  const [newExecutions, setNewExecutions] = React.useState<Execution[]>(
    JSON.parse(JSON.stringify(executions))
  );

  const getStatusStyles = (status: Execution["status"]): string => {
    switch (status) {
      case "COMPLETED":
        return "bg-green-100 text-green-800";
      case "RUNNING":
        return "bg-blue-100 text-blue-800";
      case "FAILED":
        return "bg-red-100 text-red-800";
      case "DELETED":
        return "bg-red-100 text-red-800";
      default:
        return "";
    }
  };

  const [menuState, setMenuState] = useState({
    visible: { delete: true, download: true },
    processing: { delete: false, download: false },
    disabled: { download: true},
  });
    
  const handleAction = (action: any) => {
    switch(action.option){
      case "delete" :
        handleDelete(action.element)
      break;
      case "download" :
        handleDownload(action.element)
      break;
      default:
        toast.info(`${action.option} is option is not implemented!`)
    }
  };

  const handleDelete = async (execution:Execution) => {
    try {
      setMenuState((prevState) => ({
        ...prevState, processing: {...prevState.processing, delete: true}
      }));
      await backendAPI.delete(`/project-management/projects/${project.projectId}/executions/${execution.id}`);
      toast.success("Execution Deleted Successfully");
      onDeleteSuccess(execution.id)
    } catch (error) {
      if (error instanceof AxiosError && error.response) {
        toast.error(
          error.response?.data?.error ||
          "An error occurred while Deleting Execution."
        );
      } else {
        toast.error("An unknown error occurred. Please try again later.");
      }
    } finally {
      setMenuState((prevState) => ({
        ...prevState, processing: {...prevState.processing, delete: false}
      }));
    }
  };

  const handleDownload = async (execution:Execution) => {
  
    if(execution.status !== "COMPLETED"){
      toast.warning("Result File is not available")
      return
    }
    if(menuState.processing.download === true){
      toast.info("Please Wait! Downloading Already in a queue.")
      return
    }
    try {

      setMenuState((prevState) => ({
        ...prevState, processing: {...prevState.processing, download: true}
      }));

      const response = await backendAPI.get(
        `/project-management/projects/${project.id}/executions/${execution.id}/downloadResult`,
        { responseType: 'blob' }
      );
      const blob = new Blob([response.data], { type: 'application/zip' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `${execution.name}.zip`;
      document.body.appendChild(link);
      link.click();
      link.remove();
      URL.revokeObjectURL(url);
    } catch (error) {
      if (error instanceof AxiosError && error.response) {
        toast.error(
          error.response?.data?.error ||
            "An error occurred while fetching result file."
        );
      } else {
        toast.error("An unknown error occurred. Please try again later.");
      }
    } finally {
      setMenuState((prevState) => ({
        ...prevState, processing: {...prevState.processing, download: false}
      }));
    }
    
  };

  const [dropdownOpen, setDropdownOpen] = useState<number | null>(null);

  const columns = React.useMemo(
    () => [
      {
        header: "Name",
        accessor: "name" as const,
      },
      {
        header: "Status",
        accessor: "status" as const,
        render: (newExecutions: Execution) => 
        <div className="flex items-center">
          <p
            className={`px-3 py-1 rounded-md text-sm ${getStatusStyles(
              newExecutions.status
            )}`}
          >
            {newExecutions.status}
          </p>
          {newExecutions.statusReason && (
            <div className="relative group">
              <div className="absolute bottom-10 left-1/2 -translate-x-1/2 w-44 z-10 text-sm opacity-0 transition-opacity group-hover:opacity-100 bg-opacity-20 backdrop-blur-md p-2 pointer-events-none bg-slate-300 rounded-md shadow-md text-center text-gray-500">
                <p className="break-words">
                  {newExecutions.statusReason}
                </p>
              </div>
              <Info
                className={` ${getStatusStyles(
                  newExecutions.status
                )} bg-transparent p-1 group-hover:opacity-100 cursor-pointer`}
              />
            </div>
          )}
        </div>
      },
      {
        header: "Date Created",
        accessor: "dateCreated" as const,
        render: (newExecutions: Execution) => getDateFromTimestamp(newExecutions.dateCreated, true, true),
        
      },
      {
        header: "Actions",
        accessor: "actions" as const,
        render: (newExecutions: Execution, index: number) => (
        <div className=" flex justify-end items-center">
          <ThreeDot
            visible={menuState.visible}
            processing={menuState.processing}
            disabled={menuState.disabled}
            onAction={handleAction}
            on={newExecutions}
            isOpen={dropdownOpen === index}
            toggleDropdown={(event:any) => {
              if (event === null) {
                setDropdownOpen(null);
                return;
              }
              event.stopPropagation();

              setMenuState((prevState) => ({
                ...prevState, disabled: {...prevState.disabled, download: newExecutions.status !== "COMPLETED"}
              }));
          
              setDropdownOpen(dropdownOpen === index ? null : index);
            }}
          />
        </div>
        ),
      },
    ],
    [dropdownOpen]
  );

  const onDeleteSuccess = (id: string) => {
    const updatedExecutions = newExecutions.map((execution: Execution) =>
      execution.id === id ? { ...execution, status: "DELETED" } : execution
    );
    setNewExecutions(updatedExecutions);
  };

  return (
    <div className="border-t border-b bg-gradient-to-b from-gray-50/50 to-white">
      <div className="m-4">
        <div className="flex items-center justify-between mb-4 px-3">
          <div className="flex items-center gap-2">
            <div className="h-2 w-2 rounded-full bg-teal-500"></div>
            <p className="text-sm font-semibold text-gray-700">
              Execution History
            </p>
          </div>
          <div className="px-3 py-1.5 bg-gray-100/70 backdrop-blur-sm rounded-full border border-gray-200/50 shadow-sm">
            <p className="text-xs font-medium text-gray-600">
              {newExecutions.length}{" "}
              {newExecutions.length === 1 ? "execution" : "executions"}
            </p>
          </div>
        </div>

        <div className="rounded-lg bg-white shadow-[0_4px_12px_-2px_rgba(0,0,0,0.08)] border border-gray-200/80">
          <div className="bg-gradient-to-r from-gray-50/90 to-white border-b border-gray-100 px-4 py-2.5">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-2 text-xs text-gray-500">
                <span className="inline-block w-1.5 h-1.5 rounded-full bg-purple-400"></span>
                Language:{" "}
                <span className="font-medium text-gray-700">
                  {project.evaluatorLanguage}
                </span>
              </div>
              <div className="text-xs text-gray-500">
                Last updated: {newExecutions[0]?.dateCreated || "No executions"}
              </div>
            </div>
          </div>

          <Table
            data={newExecutions}
            columns={columns}
            onRowClick={() => setDropdownOpen(null)}
            striped={true}
            className="w-full"
          />

          {newExecutions.length === 0 && (
            <div className="px-4 py-8 text-center text-gray-500">
              <p className="text-sm">No executions found for this project</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ExecutionDetails;
