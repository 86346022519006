export const formatStatus = (
	status: "CREATED" | "APPROVED" | "REJECTED" | "DELETED"
): string | React.ReactNode => {
	if (status === "CREATED")
		return (
			<p className="px-3 py-1 text-center bg-blue-100 text-blue-800 rounded-md text-sm w-fit ">
				APPROVAL PENDING
			</p>
		);
	if (status === "APPROVED")
		return (
			<p className="px-3 py-1 text-center bg-green-100 text-green-800 text-sm rounded-md font-medium w-fit">
				{status}
			</p>
		);
	if (status === "REJECTED")
		return (
			<p className="px-3 py-1 text-center bg-red-100 text-red-800 text-sm font-medium rounded-md w-fit ">
				{status}
			</p>
		);
	if (status === "DELETED")
		return <p className="text-sm  w-fit font-medium ">{status}</p>;
	return status;
};

export const formatLanguage = (language: "PYTHON" | "MATLAB" | "CPP"): string => {
	return language === "PYTHON" ? "Python" : language;
};