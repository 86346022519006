import { AxiosError } from "axios";
import React, { useEffect, useState } from "react";
import { toast, Toaster } from "sonner";
import backendAPI from "../../../services/apiRequestService";
import EvaluatorModal from "./EvaluatorModal";
import ViewEvaluatorDetails from "./ViewEvaluatorDetails";
import { useAppSelector } from "../../../redux/hooks";
import Table from "../../common/Table";
import { getDateFromTimestamp } from "../../common/DateAndTime";
import { formatLanguage, formatStatus } from "../../common/Formatter";
import PendingEvaluator from "./PendingEvaluator";
import { Evaluator } from "../../../types/types";
import { getUserAccess } from "../../../services/KeycloakService";
import ThreeDot from "../../common/ThreeDot";

const EvaluatorTable: React.FC = () => {
  const [evaluators, setEvaluators] = useState<Evaluator[]>([]);
  const [pendingEvaluators, setPendingEvaluators] = useState<Evaluator[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedEvaluator, setSelectedEvaluator] = useState<Evaluator | null>(
    null
  );
  const [dropdownOpen, setDropdownOpen] = useState<number | null>(null);
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [deletingEvaluator, setDeletingEvaluator] = useState<Evaluator | null>(
    null
  );
  const activeTenant = useAppSelector((state) => state.tenant.active);
  const [ isTenant, setIsTenant] = useState<boolean>(false);

  const [menuState, setMenuState] = useState({
    visible: { view: true, delete: true, download: true },
    processing: { view: false, delete: false, download: false },
    disabled: { view: false, delete: false, download: false },
  });
  
  const handleAction = (action: any) => {
    switch(action.option){
      case "view" :
        setSelectedEvaluator(action.element)
      break;
      case "delete" :
        handleDeleteClick(action.element)
      break;
      case "download" :
        handleDownload(action.element)
      break;
      default:
        toast.info(`${action.option}: Option not available`)
    }

  };

  const fetchEvaluators = async (): Promise<void> => {
    try {
      const response = await backendAPI.get(
        "/project-management/evaluators?status=CREATED&status=APPROVED&status=REJECTED"
      );
      setEvaluators(response.data);
    } catch (error) {
      setEvaluators([]);
      if (error instanceof AxiosError && error.response) {
        toast.error(
          error.response?.data?.error ||
            "An error occurred while fetching data."
        );
      } else {
        toast.error("An unknown error occurred. Please try again later.");
      }
    }
  };
  const activeTenantId = useAppSelector((state) => state.tenant.active);
  useEffect(() => {
    fetchEvaluators();
  }, [activeTenantId]);

  const handleAddEvaluator = (): void => {
    setShowModal(true);
  };

  const handleRegisterEvaluator = (evaluator:any) => {
    setShowModal(false);
    setEvaluators((prevEvaluators) => [...prevEvaluators, evaluator]);
  };

  const handleDownload = async (evaluator:any) => {
    try {
      setMenuState((prevState) => ({
        ...prevState, processing: {...prevState.processing, download: true}
      }));
			const response = await backendAPI.get(`/project-management/evaluators/${evaluator.id}/download`, { responseType: 'blob' }
			);
			const blob = new Blob([response.data], { type: 'application/json' });
			const url = URL.createObjectURL(blob);
			const link = document.createElement('a');
			link.href = url;
			link.download = evaluator.fileName;
			document.body.appendChild(link);
			link.click();
			link.remove();
			URL.revokeObjectURL(url);
		} catch (error) {
			if (error instanceof AxiosError && error.response) {
				toast.error(
					error.response?.data?.error ||
					"Evaluator file could not be downloaded."
				);
			} else {
				toast.error("An unknown error occurred. Please try again later.");
			}
		} finally {
      setMenuState((prevState) => ({
        ...prevState, processing: {...prevState.processing, download: false}
      }));
    }
  }

  const handleDeleteClick = (evaluator: Evaluator): void => {
    setDeletingEvaluator(evaluator);
    setShowConfirmation(true);
  };

  const handleDelete = async (): Promise<void> => {
    if (!deletingEvaluator) return;
    try {
      await backendAPI.delete(
        `/project-management/evaluators/${deletingEvaluator.id}`
      );
      setEvaluators((prevEvaluators) =>
        prevEvaluators.filter(
          (evaluator) => evaluator.id !== deletingEvaluator.id
        )
      );
      toast.success("Evaluator deleted successfully.");
    } catch (error) {
      if (error instanceof AxiosError && error.response) {
        toast.error("Error deleting the Evaluator");
      } else {
        toast.error("An unknown error occurred while deleting the evaluator.");
      }
    } finally {
      setShowConfirmation(false);
      setDeletingEvaluator(null);
    }
  };

  useEffect(() => {
    if(evaluators.length > 0){
      setPendingEvaluators(evaluators.filter((evaluator:{ status: string; }) => evaluator.status === "CREATED"));
    }else{
      setPendingEvaluators([]);
    }
  },[evaluators])

  const handleCancelDelete = (): void => {
    setShowConfirmation(false);
    setDeletingEvaluator(null);
  };

  const columns = React.useMemo(
    () => [
      {
        header: "Name",
        accessor: "name" as const,
      },
      {
        header: "Language",
        accessor: "language" as const,
        render: (evaluator: Evaluator) => formatLanguage(evaluator.language),
      },
      {
        header: "Status",
        accessor: "status" as const,
        render: (evaluator: Evaluator) => formatStatus(evaluator.status),
      },
      {
        header: "Date Created",
        accessor: "createdAt" as const,
        render: (evaluator: Evaluator) =>
          getDateFromTimestamp(parseInt(evaluator.createdAt)),
      },
      {
        header: "Actions",
        accessor: "actions" as const,
        render: (evaluator: Evaluator, index: number) => 
        <div className=" flex justify-end items-center">
          <ThreeDot
            visible={menuState.visible}
            processing={menuState.processing}
            disabled={menuState.disabled}
            onAction={handleAction}
            on={evaluator}
            isOpen={dropdownOpen === index}
            toggleDropdown={(event:any) => {
              if (event === null) {
                setDropdownOpen(null);
                return;
              }
              event.stopPropagation();
              setDropdownOpen(dropdownOpen === index ? null : index);
            }}
          />
        </div>,
      }
    ],
    [dropdownOpen, evaluators.length]
  );

  useEffect(() => {
    if(activeTenant){
      getUserAccess(activeTenant).includes('TENANT_ADMIN') ? setIsTenant(true) : setIsTenant(false)
    }
  },[activeTenant])

  return (
    <div className="pt-4 shadow-inner bg-white">
      <Toaster richColors />
      <div className="flex justify-between items-center p-4">
        <h2 className="text-lg font-bold">
          Total Evaluators - {evaluators.length > 0 ? evaluators.length : "0"}
        </h2>
        <button
          className="bg-black text-white px-4 py-2 rounded-md flex items-center"
          onClick={handleAddEvaluator}
        >
          + Add Evaluator
        </button>
      </div>
      <div>
        <Table
          data={evaluators}
          columns={columns}
          onRowClick={() => setDropdownOpen(null)}
          striped={true}
          className="w-full"
        />
        {isTenant && <PendingEvaluator />}
      </div>
      {showModal && (
        <EvaluatorModal
          onClose={() => setShowModal(false)}
          onRegister={handleRegisterEvaluator}
        />
      )}

      {selectedEvaluator && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <ViewEvaluatorDetails
            evaluator={selectedEvaluator}
            onClose={() => setSelectedEvaluator(null)}
          />
        </div>
      )}

      {showConfirmation && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-30">
          <div className="bg-white p-6 rounded-md shadow-lg w-96">
            <div className="border-b pb-4 mb-4">
              <h3 className="text-lg font-semibold text-center">
                Are you sure you want to delete?
              </h3>
            </div>
            <div className="flex justify-around pt-4 mt-4">
              <button
                className="bg-teal-600 hover:bg-gray-600 text-white px-4 py-2 rounded-md"
                onClick={handleDelete}
              >
                Yes
              </button>
              <button
                className="bg-teal-600 hover:bg-gray-600 text-white px-4 py-2 rounded-md"
                onClick={handleCancelDelete}
              >
                No
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EvaluatorTable;
