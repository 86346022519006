import { Loader, LucideIcon, PackagePlus, Sigma, Square, Triangle, X } from "lucide-react";
import React, { useState } from "react";
import { toast, Toaster } from "sonner";
import backendAPI from "../../../services/apiRequestService";

interface ProjectData {
  id: string;
  name: string;
  simulationType: string;
  evaluatorId: string;
  evaluatorName: string;
  evaluatorFunctionName: string;
  evaluatorLanguage: string;
  evaluatorFileName: string;
}

interface ProjectModalProps extends React.PropsWithChildren {
  onClose: () => void;
  onAdd: (project: ProjectData & { dateCreated: string }) => void;
}

interface Evaluator {
  id: string;
  name: string;
  language: string;
  date: string;
}

interface SimulationType {
  name: string;
  label: string;
  icon: LucideIcon;
  disabled: boolean;
}

export const ProjectModal = React.forwardRef<HTMLDivElement, ProjectModalProps>(
  ({ onClose, onAdd }, ref) => {
    const [projectData, setProjectData] = useState<ProjectData>({
      id: "",
      name: "",
      simulationType: "",
      evaluatorId: "",
      evaluatorName: "",
      evaluatorFunctionName: "",
      evaluatorLanguage: "",
      evaluatorFileName: "",
    });
    const [evaluators, setEvaluators] = useState<Evaluator[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmiting, setIsSubmitting] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const simulationTypes: SimulationType[] = [
      { name: "Shape", label:"Shape" , icon: Square, disabled: true },
      { name: "Topology", label:"Topology", icon: Triangle, disabled: true },
      { name: "General", label:"General", icon: Sigma, disabled: false },
    ];

    const fetchEvaluators = async (): Promise<void> => {
      setIsLoading(true);
      setError(null);
      backendAPI
        .get("/project-management/evaluators?status=APPROVED")
        .then((response) => {
          const formattedEvaluators = response.data.map((item: any) => ({
            id: item.id,
            name: item.name,
            language: item.language,
            date: new Date(item.createdAt).toLocaleDateString("en-US", {
              day: "2-digit",
              month: "short",
              year: "numeric",
            }),
          }));
          setEvaluators(formattedEvaluators);
        })
        .catch((error) => {
          toast.error(error.message);
          error.status === 404 ? setError("No Approved Evaluators Available") :  setError(error.message) ;
          console.info("Error : ", error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    };

    const handleSimulationTypeSelect = (typeName: string): void => {
      setProjectData({ ...projectData, simulationType: typeName });
      if (typeName === "General") {
        fetchEvaluators();
      }
    };

    const handleEvaluatorSelect = (evaluatorId: string): void => {
      setProjectData({ ...projectData, evaluatorId });
    };

    const createProject = async (): Promise<void> => {
      if (
        projectData.name === "" ||
        projectData.simulationType === "" ||
        projectData.evaluatorId === ""
      ) {
        toast.error("Please ensure all fields are filled out.");
        return;
      }
      const formData = {
        "name": projectData.name,
        "simulationType": projectData.simulationType,
        "evaluator": {
          "id": projectData.evaluatorId
        }
      }
      setIsSubmitting(true);
      backendAPI
        .post("/project-management/projects", formData)
        .then((response) => {
          const newProject = response.data;

          const selectedEvaluator = evaluators.find(
            (e) => e.id === projectData.evaluatorId
          );

          if (!selectedEvaluator) {
            toast.error("Could not find evaluator information");
            return;
          }

          onAdd({
            id: newProject.id,
            name: newProject.name,
            simulationType: newProject.simulationType,
            evaluatorId: projectData.evaluatorId,
            evaluatorName: selectedEvaluator.name,
            evaluatorFunctionName:
              newProject.evaluator?.functionName || "custom", // provide default
            evaluatorLanguage: selectedEvaluator.language,
            evaluatorFileName:
              newProject.evaluator?.fileName || "customEvaluator.py", // provide default
            dateCreated: new Date(newProject.createdAt).toLocaleDateString(
              "en-US",
              {
                day: "2-digit",
                month: "short",
                year: "numeric",
              }
            ),
          });
          onClose();
        })
        .catch((error) => {
          toast.error(
            error.response?.data?.details || "Please check the fields."
          );
          console.error("Error creating project:", error);
          setError("Failed to create project");
        }).finally(() => {
          setIsSubmitting(false);
        })
    };
    const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
      e.preventDefault();
      createProject();
    };

    return (
      <div
        ref={ref}
        className="fixed inset-0 backdrop-blur-sm bg-opacity-50 bg-black flex items-center justify-center z-50"
      >
        <Toaster richColors />
        <div className="bg-white bg-opacity-95 rounded-lg p-6 w-full max-w-2xl">
          <div className="mb-6 flex justify-between items-center">
            <h2 className="text-xl font-bold">Add Project(s)</h2>
            <button
              type="button"
              onClick={onClose}
              className="text-gray-500 hover:text-gray-700 text-2xl"
            >
              <X className="h-5 w-5 text-gray-500" />
            </button>
          </div>
          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="space-y-4">
              <div>
                <label className="block mb-2 text-sm font-medium">Name</label>
                <input
                  type="text"
                  placeholder="Enter the name here"
                  className="w-full p-2 border rounded-md text-sm shadow-inner"
                  value={projectData.name}
                  onChange={(e) =>
                    setProjectData({ ...projectData, name: e.target.value })
                  }
                />
              </div>

              <div>
                <label className="block mb-2 text-sm font-medium">
                  Optimization Type
                </label>
                <div className="flex gap-4">
                  {simulationTypes.map((type) => {
                    const IconComponent = type.icon;
                    return (
                      <button
                        key={type.name}
                        type="button"
                        disabled={type.disabled}
                        className={`p-4 border rounded-md text-sm w-20 h-20 ${
                          type.disabled
                            ? "opacity-50 cursor-not-allowed bg-gray-100"
                            : projectData.simulationType === type.name
                              ? "border-blue-500 bg-teal-50"
                              : "border-gray-200 hover:border-blue-200"
                        }`}
                        onClick={() =>
                          !type.disabled &&
                          handleSimulationTypeSelect(type.name)
                        }
                      >
                        <div className="flex flex-col items-center gap-2">
                          <IconComponent className="w-6 h-6" />
                          <span>{type.label}</span>
                        </div>
                      </button>
                    );
                  })}
                </div>
              </div>

              {projectData.simulationType === "General" && (
                <div>
                  <label className="block mb-2 text-sm font-medium">
                    Choose your Evaluator
                  </label>
                  {isLoading ? (
                    <p>Loading evaluators...</p>
                  ) : error ? (
                    <p className="text-red-500">{error}</p>
                  ) : (
                    <div className="space-y-2 h-44 overflow-y-auto shadow-inner">
                      {evaluators.map((evaluator) => (
                        <div
                          key={evaluator.id}
                          className={`p-4 border rounded-md cursor-pointer ${
                            projectData.evaluatorId === evaluator.id
                              ? "border-blue-500 bg-teal-50"
                              : "border-gray-200 hover:border-blue-500"
                          }`}
                          onClick={() => handleEvaluatorSelect(evaluator.id)}
                        >
                          <div className="flex justify-between items-center">
                            <span className=" text-md">{evaluator.name}</span>
                            <div className="text-sm text-gray-500">
                              <span>Language: {evaluator.language}</span>
                              <span className="ml-4">{evaluator.date}</span>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              )}
            </div>

            <div className="pt-2 mt-2 border-t flex justify-end">
              <button
                type="submit"
                disabled={isSubmiting}
                className={` ${isSubmiting ? "opacity-50 cursor-not-allowed" : ""} px-3 items-center py-1.5 flex gap-3 rounded-md bg-teal-600 text-white hover:bg-teal-700`}
              >
                {isSubmiting ? (
                      <Loader size={20} className="animate-spin" />
                    ) : (
                      <PackagePlus size={20} />
                    )}
                    <p>Add Project</p>
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
);

ProjectModal.displayName = "ProjectModal";
export default ProjectModal;
