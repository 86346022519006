import React, { useState } from "react";
import Sidebar from "../../components/common/Sidebar";
import Header from "../../components/common/Header";
import ProjectTable from "../../components/layout/projects/ProjectTable";
import ProjectStats from "../../components/layout/projects/ProjectStats";
import { Project } from "../../types/project";
import { getTenantList } from "../../services/KeycloakService";
import NoTenant from "./NoTenant";
interface SidebarProps {
  isOpen: boolean;
}

interface HeaderProps {
  onToggleSidebar: () => void;
}

interface ProjectTableProps {
  projects: Project[];
  onProjectSelect: (project: Project | null) => void;
  onProjectUpdate: (projects: Project[]) => void;
}

interface ProjectStatsProps {
  selectedProject: Project | null;
  allProjects: Project[];
}

export const ProjectPage: React.FC = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(true);
  const [projects, setProjects] = useState<Project[]>([]);
  const [selectedProject, setSelectedProject] = useState<Project | null>(null);

  const handleToggleSidebar = (): void => {
    setIsSidebarOpen((prev) => !prev);
  };

  const handleProjectUpdate = (updatedProjects: Project[]): void => {
    setProjects(updatedProjects);
  };

  const tenants = getTenantList();
  return (
    <div className="flex">
      <Sidebar isOpen={isSidebarOpen} />

      <div className="flex-1 flex flex-col">
        <Header onToggleSidebar={handleToggleSidebar} />
        {tenants.length === 0 ? <NoTenant/>
        : <ProjectTable
          projects={projects}
          onProjectSelect={setSelectedProject}
          onProjectUpdate={handleProjectUpdate}
        />}
        
      </div>

      <ProjectStats selectedProject={selectedProject} allProjects={projects} />
    </div>
  );
};

export default ProjectPage;
