import React from "react";

interface InputFieldProps {
  id: string;
  label: string;
  type?: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
  styleOuter?: string;
  styleLabel?: string;
  styleInput?: string;
}

const InputField: React.FC<InputFieldProps> = ({
  id,
  label,
  type = "text",
  value,
  onChange,
  required = false,
  styleOuter="",
  styleLabel="",
  styleInput=""
}) => (
  <div className={`${styleOuter}`}>
    <label htmlFor={id} className={`block font-medium mb-1 text-sm ${styleLabel}`}>
      {label}
    </label>
    <input
      type={type}
      id={id}
      value={value}
      onChange={onChange}
      className={`border rounded-md px-3 py-1.5 text-sm w-full shadow-inner ${styleInput}`}
      required={required}
    />
  </div>
);

export default InputField;
