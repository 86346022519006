import React, { useEffect, useState } from "react";
import { toast, Toaster } from "sonner";
import { useAppSelector } from "../../../redux/hooks";
import backendAPI from "../../../services/apiRequestService";
import {
  ExecutionStatus,
  Project,
  ProjectsTableProps,
} from "../../../types/types";
import { getDateFromTimestamp } from "../../common/DateAndTime";
import ExecutionDetails from "./ExecutionDetails";
import ProjectModal from "./ProjectModal";
import Switch from "./components/Switch";

const ProjectsTable: React.FC<ProjectsTableProps> = ({
  onProjectSelect,
  onProjectUpdate,
}) => {
  const [projects, setProjects] = useState<Project[]>([]);
  const [showExecuteModal, setShowExecuteModal] = useState<boolean>(false);
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
  const [showServerDetailsModal, setShowServerDetailsModal] =
    useState<boolean>(false);
  const [selectedProject, setSelectedProject] = useState<Project | null>(null);
  const [expandedProjectId, setExpandedProjectId] = useState<number | null>(
    null
  );
  const activeTenantId = useAppSelector((state) => state.tenant.active);

  useEffect(() => {
    backendAPI
      .get("/project-management/projects")
      .then((response) => {
        const projects = response.data.map((project: any) => ({
          id: project.id,
          name: project.name,
          simulationType: project.simulationType,
          evaluatorId: project.evaluator.id,
          evaluatorName: project.evaluator.name,
          evaluatorFileName: project.evaluator.fileName,
          evaluatorFunctionName: project.evaluator.functionName,
          evaluatorLanguage: project.evaluator.language,
          dateCreated: getDateFromTimestamp(project.createdAt),
          executions: [],
        }));
        setProjects(projects);
      })
      .catch((error) => {
        setProjects([]);
        toast.error("No project Found");
        console.info("Error : ", error);
      });
  }, [activeTenantId]);

  useEffect(() => {
    onProjectUpdate?.(projects);
  }, [onProjectUpdate, projects]);

  useEffect(() => {
    onProjectSelect?.(selectedProject);
  }, [onProjectSelect, selectedProject]);

  const fetchExecutions = async (projectId: string) => {
    try {
      const response = await backendAPI.get(
        `/project-management/projects/${projectId}/executions`
      );
      const executions = response.data.map((execution: any) => ({
        id: execution.id,
        name: `Execution ${execution.id}`,
        generations: 0,
        statusReason: execution.statusReason,
        status: execution.status.toUpperCase() as ExecutionStatus,
        dateCreated: getDateFromTimestamp(execution.startedAt, true, true),
      }));

      return executions;
    } catch (error: any) {
      console.info("Error fetching executions: ", error);
      return [];
    }
  };

  const handleProjectClick = async (activeProject: any): Promise<void> => {
    const project = projects.find((p) => p.id === activeProject.id);
    if (!project) return;

    if (expandedProjectId === project.id) {
      setExpandedProjectId(null);
      setSelectedProject(null);
      return;
    }
    try {
      const executions = await fetchExecutions(project.id.toString());
      if (executions.length < 1) {
        toast.info(`No Execution Found for project : ${project.name}`);
        return;
      }

      const updatedProjects = projects.map((p) =>
        p.id === activeProject.id ? { ...p, executions } : p
      );
      setProjects(updatedProjects);
      setExpandedProjectId(activeProject.id);
      setSelectedProject({ ...project, executions });
    } catch (error) {
      console.error("Error handling project click:", error);
    }
  };

  const handleAddProject = (newProject: any): void => {
    const updatedProjects = [
      ...projects,
      { ...newProject, id: newProject.id, executions: [] },
    ];
    setProjects(updatedProjects);
  };

  const showExecute = (e: any, project: any) => {
    e.stopPropagation();
    setSelectedProject({ ...project, executions: [] });
    setShowServerDetailsModal(true);
  };

  const handleServerDetailsNext = (): void => {
    setShowServerDetailsModal(false);
    setShowExecuteModal(true);
  };

  const onClose = () => {
    localStorage.removeItem("selectedServer")
    setExpandedProjectId(null);
    setSelectedProject(null);
    setShowExecuteModal(false);
    setShowServerDetailsModal(false);
  };
  return (
    <div className="flex-1 bg-gray-50 shadow-inner">
      <Toaster richColors />
      <div className="flex justify-between items-center p-4">
        <h2 className="text-lg font-bold">
          Total Projects - {projects.length > 0 ? projects.length : "0"}
        </h2>
        <button
          type="button"
          className="bg-black text-white px-4 py-2 rounded-md flex items-center"
          onClick={() => setShowCreateModal(true)}
        >
          + Create Project
        </button>
      </div>
      <div className="overflow-x-auto">
        <table className="w-full border-collapse">
          <thead>
            <tr className="bg-gray-100 text-gray-500">
              <th className="px-4 py-3 font-medium text-left">Name</th>
              <th className="px-4 py-3 font-medium text-left">Type</th>
              <th className="px-4 py-3 font-medium text-left">Evaluator</th>
              <th className="px-4 py-3 font-medium text-left">Date Created</th>
              <th className="px-4 py-3 font-medium text-right">
                <p className="mr-4">Actions</p>
              </th>
            </tr>
          </thead>
          <tbody>
            {projects.map((project, index) => (
              <React.Fragment key={project.id}>
                <tr
                  onClick={() => handleProjectClick(project)}
                  className={`border-b hover:bg-gray-100 cursor-pointer ${
                    index % 2 === 0 ? "bg-white" : "bg-gray-50"
                  } ${expandedProjectId === project.id ? "bg-blue-50" : ""}`}
                >
                  <td className="p-4 pl-4 py-3">{project.name}</td>
                  <td className="px-4 py-3">{project.simulationType}</td>
                  <td className="px-4 py-3">{project.evaluatorName}</td>
                  <td className="px-4 py-3">{project.dateCreated}</td>
                  <td className="px-4 py-3 text-right w-1">
                    <button
                      type="button"
                      onClick={(e) => showExecute(e, project)}
                      className="bg-red-500 text-white px-4 py-1 rounded-md hover:bg-red-600"
                    >
                      Execute
                    </button>
                  </td>
                </tr>
                {expandedProjectId === project.id && (
                  <tr className="expanded-row">
                    <td colSpan={5} className="p-0 border-b">
                      <div className="bg-gray-50">
                        <ExecutionDetails
                          project={project}
                          executions={project.executions}
                        />
                      </div>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
      {showServerDetailsModal && selectedProject && (
        <Switch
           project={selectedProject}
          onClose={onClose}
          onNext={handleServerDetailsNext}

        />
      )}
      {showExecuteModal && selectedProject && (
        <></>
      )}
      {showCreateModal && (
        <ProjectModal
          onClose={() => setShowCreateModal(false)}
          onAdd={handleAddProject}
        />
      )}
    </div>
  );
};

export default ProjectsTable;

