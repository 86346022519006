import React from "react";

interface TableHeadProps {
  headers: string[];
}

const TableHead: React.FC<TableHeadProps> = ({ headers }) => {
  return (
    <thead>
      <tr className="bg-gray-100 text-gray-500">
        {headers.map((header, index) => (
          <th key={index} className={` px-6 py-3 font-medium text-left ${ headers.length - 1 === index && ' text-right'}`}>
            {header}
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default TableHead;
