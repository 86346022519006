import React, {useState} from 'react'

interface ToggleBtnProps {
	enabled:boolean;
	onChange:any;
}

const ToggleBtn :React.FC<ToggleBtnProps> = ({enabled, onChange}) => {
	const [enable, setEnable] = useState(enabled);

	const handleChange = (e:any) => {
		e.preventDefault()
		setEnable(!enable)
		onChange(!enable)
	}
	
  return (
	<button className='relative  scale-75' onClick={handleChange}>
		<div className={` ${enable ? " border-teal-600 " : " border-gray-500 "} h-6 w-12 bg-slate-200 border rounded-full`}></div>
		<div className={`absolute top-[2px] ${enable ? " left-[26px] bg-teal-600 " : " left-[2px] bg-slate-500 "} transition-all h-5 w-5 rounded-full `}></div>
	</button>
  )
}

export default ToggleBtn