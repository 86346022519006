import React from "react";
import { Link, useLocation } from "react-router-dom";
import {
  FolderClosed,
  Shield,
  Server,
  Users,
  LayoutDashboard,
} from "lucide-react";

interface SidebarProps {
  isOpen: boolean;
}

const Sidebar: React.FC<SidebarProps> = ({ isOpen }) => {
  const location = useLocation();

  return (
    <aside
      className={`p-2 min-h-screen h-full transition-all bg-white border-l ${isOpen ? " min-w-40 opacity-100" : " min-w-0 opacity-0"}`}
    >
    { isOpen && <>
      <div className="flex items-center gap-2 text-lg font-semibold mt-4 mb-8">
        <LayoutDashboard className="w-5 h-5" /> Dashboard
      </div>
      <nav className="space-y-1">
        <Link
          to="/projects"
          className={`flex items-center p-2 transition-all rounded-md ${location.pathname === "/projects"
              ? "text-indigo-600 bg-indigo-100"
              : "bg-white hover:bg-gray-200"
            }`}
        >
          <FolderClosed className="w-5 h-5 mr-2" /> Projects
        </Link>
        <Link
          to="/evaluators"
          className={`flex items-center p-2 transition-all rounded-md ${location.pathname === "/evaluators"
              ? "text-indigo-600 bg-indigo-100"
              : "bg-white hover:bg-gray-200"
            }`}
        >
          <Shield className="w-5 h-5 mr-2" /> Evaluators
        </Link>
        <Link
          to="/servers"
          className={`flex items-center p-2 transition-all rounded-md ${location.pathname === "/servers"
              ? "text-indigo-600 bg-indigo-100"
              : "bg-white hover:bg-gray-200"
            }`}
        >
          <Server className="w-5 h-5 mr-2" /> Servers
        </Link>
        {/* <Link
              to="/users"
              className={`flex items-center p-2 rounded-md whitespace-nowrap ${
                location.pathname === "/users"
                  ? "text-indigo-600 bg-indigo-100"
                  : "hover:bg-gray-200"
              }`}
            >
              <Users className="w-5 h-5 mr-2" /> Manage Users
            </Link> */}
      </nav>
    </>}
    </aside>
  );
};

export default Sidebar;
