import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { EvaluatorPage } from "../pages/layout/EvaluatorPage";
import { ProjectPage } from "../pages/layout/ProjectPage";
import { ServerPage } from "../pages/layout/ServerPage";
import ManageUsers from "../pages/layout/ManageUsers";

const AppRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/evaluators" element={<EvaluatorPage />} />
      <Route path="/projects" element={<ProjectPage />} />
      <Route path="/servers" element={<ServerPage />} />
      <Route path="/users" element={<ManageUsers />} />
      <Route path="/" element={<Navigate to="/projects" />} />
      <Route path="*" element={<Navigate to="/projects" />} />
    </Routes>
  );
};

export default AppRoutes;
