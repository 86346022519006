import React, { useRef, useState } from "react";
import { toast, Toaster } from "sonner";
import { ExecuteModalProps, ExecutionParams } from "../../../types/types";
import FieldInfo from "./components/FieldInfo";

const initialFieldValidity = {
  populationSize: false,
  designVariables: false,
  maxGenerations: false,
  deltaTheta: false,
  functionToleranceLimit: false,
  designVariableToleranceLimit: false,
  trials: false,
  lowerBound: true,
  upperBound: true,
};

const ExecuteModal: React.FC<
  ExecuteModalProps & { setPayload: (payload: any) => void }
> = ({ project, onClose, setIndex, setPayload }) => {
  const [params, setParams] = useState<ExecutionParams>({
    populationSize: undefined,
    designVariables: undefined,
    maxGenerations: undefined,
    deltaTheta: undefined,
    functionToleranceLimit: undefined,
    designVariableToleranceLimit: undefined,
    typeOfOptimization: "continuous",
    lowerBound: undefined,
    upperBound: undefined,
    trials: undefined,
  });

  const boundGlobalStatus = useRef<any>([true, true]);

  const [lowerBoundChecked, setLowerBoundChecked] = useState(true);
  const [upperBoundChecked, setUpperBoundChecked] = useState(true);

  const [nextDisabled, setNextDisabled] = useState(true);
  const [fieldValidity, setFieldValidity] = useState(initialFieldValidity);

  const checkValidBound = (
    boundStatus: boolean,
    boundValue: string | undefined,
    boundName: string
  ) => {
    let designVariable: any = params.designVariables;
    designVariable = parseInt(designVariable);

    if (params.typeOfOptimization === "continuous" && boundValue) {
      const boundListStr = boundValue.split(",");
      const boundListNum = boundListStr.map(Number);
      let arr = [];
      if (boundStatus && boundListNum.length === 1) {
        arr = Array(designVariable).fill(boundListNum[0]);
        return arr;
      }
      if (boundStatus && boundListNum.length !== 1) {
        toast.error(
          `${boundName} : If the Global is Checked, only a single value is allowed.`
        );
        return false;
      }
      if (boundListNum.length !== designVariable) {
        toast.error(`Missing ${boundName}'s value(s) `);
        return false;
      }
      arr = boundListNum.slice(0, designVariable);
      return arr;
    } else {
      toast.error(`${boundName}'s value(s) is/are missing.`);
      return false;
    }
  };

  const handleNext = async (
    e?: React.FormEvent<HTMLFormElement>
  ): Promise<boolean> => {
    if (e) {
      e.preventDefault();
    }
    const serverId = localStorage.getItem("selectedServer");
    let lowerBound, upperBound;
    if (params.typeOfOptimization === "continuous") {
      lowerBound = checkValidBound(
        boundGlobalStatus.current[0],
        params.lowerBound,
        "Lower Bound"
      );
      upperBound = checkValidBound(
        boundGlobalStatus.current[1],
        params.upperBound,
        "Upper Bound"
      );
      if (lowerBound === false || upperBound === false) return false;
    }
    const payload = {
      server: {
        id: serverId,
      },
      qieoConfig: {
        populationSize:
          params.populationSize !== undefined
            ? Number(params.populationSize)
            : undefined,
        typeOfOptimization: params.typeOfOptimization,
        maxGenerations:
          params.maxGenerations !== undefined
            ? Number(params.maxGenerations)
            : undefined,
        designVariables:
          params.designVariables !== undefined
            ? Number(params.designVariables)
            : undefined,
        ...(params.typeOfOptimization === "continuous"
          ? {
              lowerBounds: lowerBound,
              upperBounds: upperBound,
            }
          : {}),
        deltaTheta:
          params.deltaTheta !== undefined
            ? Number(params.deltaTheta)
            : undefined,
        trials: params.trials !== undefined ? Number(params.trials) : undefined,
        functionToleranceLimit:
          params.functionToleranceLimit !== undefined
            ? Number(params.functionToleranceLimit)
            : undefined,
        designVariableToleranceLimit:
          params.designVariableToleranceLimit !== undefined
            ? Number(params.designVariableToleranceLimit)
            : undefined,
        debsApproach: "true",
      },
    };
    setPayload(payload);
    return true;
  };

  const checkInputContraints = (
    e: React.ChangeEvent<HTMLInputElement>,
    payloadValue: string,
    minVal: number,
    maxVal: number
  ): boolean => {
    const val = parseFloat(payloadValue);
    if (isNaN(val) || val < minVal || val > maxVal) {
      e.target.classList.add("border-red-500");
      return false;
    } else {
      e.target.classList.remove("border-red-500");
      return true;
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target;
    let validCheck = false;
    switch (name) {
      case "populationSize":
        validCheck = checkInputContraints(e, value, 10, 100000);
        break;
      case "designVariables":
        validCheck = checkInputContraints(e, value, 1, 5e4);
        break;
      case "maxGenerations":
        validCheck = checkInputContraints(e, value, 10, 50000);
        break;
      case "deltaTheta":
        validCheck = checkInputContraints(e, value, -Infinity, 1);
        break;
      case "functionToleranceLimit":
        validCheck = checkInputContraints(
          e,
          value,
          0.00000000000000000001,
          0.001
        );
        break;
      case "designVariableToleranceLimit":
        validCheck = checkInputContraints(
          e,
          value,
          0.00000000000000000001,
          0.001
        );
        break;
      case "trials":
        validCheck = checkInputContraints(e, value, 1, 100);
        break;
      case "lowerBound":
        validCheck = true;
        break;
      case "upperBound":
        validCheck = true;
        break;
    }

    setFieldValidity((prev) => {
      const updatedValidity = { ...prev, [name]: validCheck };
      const allValid = Object.values(updatedValidity).every(Boolean);
      setNextDisabled(!allValid);
      return updatedValidity;
    });
    setParams((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setParams((prev) => ({
      ...prev,
      typeOfOptimization: e.target.value,
    }));
  };

  const handleBoundGlobal = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { value, checked } = e.target;
    console.log(value, checked);
    if (value === "lowerBoundCheckbox") {
      setLowerBoundChecked(!lowerBoundChecked);
      boundGlobalStatus.current[0] = checked;
    }
    if (value === "upperBoundCheckbox") {
      setUpperBoundChecked(!upperBoundChecked);
      boundGlobalStatus.current[1] = checked;
    }
  };

  return (
    <>
      <Toaster richColors expand={true} />
      {/* Header */}
      <div className="p-6 border-b flex justify-between items-center">
        <button
          type="button"
          onClick={() => setIndex(0)}
          className="text-gray-500 hover:text-gray-700 text-2xl mr-5"
        >
          ←
        </button>
        <h2 className="text-xl font-bold flex-1">Execute {project.name}</h2>
        <button
          type="button"
          onClick={onClose}
          className="text-gray-500 hover:text-gray-700 text-2xl"
        >
          ×
        </button>
      </div>

      {/* Scrollable Content */}
      <div className="overflow-y-auto p-6">
        <form onSubmit={handleNext} className="space-y-6">
          <div className="flex flex-col gap-4 ">
            {[
              {
                label: "Population Size",
                name: "populationSize",
                desc: (
                  <p>
                    For any evolutionary algorithms, the number of initial
                    points should be supplied as user input. The input value
                    should be ranging from{" "}
                    <span className=" text-red-500 font-bold">
                      10 to 100,000
                    </span>{" "}
                    and must be <strong>an Integer</strong>. Based on the
                    complexity of the problem, users can increase or decrease
                    the number of chromosomes, which greatly effects the
                    solution accuracy and time taken to complete the
                    optimization problem.
                  </p>
                ),
              },
              {
                label: "Design Variables",
                name: "designVariables",
                desc: (
                  <p>
                    Design variables/ unknowns are the output from the
                    optimization problem.{" "}
                    <i>
                      The function value is calculated based on these variables.{" "}
                    </i>
                    The user must specify the number of unknows for the current
                    function that is being optimized. For the function
                    optimization cases, this variable acts as dimensionality of
                    the problem. The entered input must be ranging from{" "}
                    <span className=" text-red-500 font-bold">1 to 5e4</span>{" "}
                    and it is <strong>an Integer</strong>.
                  </p>
                ),
              },
              {
                label: "Maximum Number of Generations",
                name: "maxGenerations",
                desc: (
                  <p>
                    This is one of the termination criteria. The moment the
                    optimizer reaches this value of maximum number of
                    generations, it terminates and provides the solution. This
                    integer input must be from{" "}
                    <span className=" text-red-500 font-bold">
                      10 to 50,000.
                    </span>
                  </p>
                ),
              },
              {
                label: "Trials",
                name: "trials",
                desc: (
                  <p>
                    If the user wants to run the same optimization problem for
                    more than once, then the user just needs to specify the
                    number of the different experiments as input. This integer
                    input should range from{" "}
                    <span className=" text-red-500 font-bold">1 to 100.</span>
                  </p>
                ),
              },
              {
                label: "Theta",
                name: "deltaTheta",
                desc: (
                  <p>
                    This input value is the maximum rotation value of the gene
                    in each generation.{" "}
                    <span className=" text-red-500 font-bold">
                      The maximum value is set as one
                    </span>
                    . You can provide <i>-negative values</i> as well, to
                    understand the algorithm.
                  </p>
                ),
              },
              {
                label: "Function Tolerance",
                name: "functionToleranceLimit",
                desc: (
                  <p>
                    This is one of the termination criteria. The optimizer
                    stops, if the change of function value between the average
                    value of last &apos;n&apos; generations and the current
                    generation is within the given tolerance limit.{" "}
                    <i>
                      {" "}
                      In the current formulation, n is fixed, and it is equal to
                      5{" "}
                    </i>
                    , i.e., the average function value of last 5 generations is
                    compared with the current generation. Ideally,{" "}
                    <span className=" text-red-500 font-bold">
                      1e-6 i.e 0.000001
                    </span>{" "}
                    is a suitable input value.
                  </p>
                ),
              },
              {
                label: "Design Variable Tolerance",
                name: "designVariableToleranceLimit",
                desc: (
                  <p>
                    This is one of the termination criteria. The optimizer stops
                    if the maximum change in design variable between the last
                    two generations is within the given tolerance limit.
                    Ideally,{" "}
                    <span className=" text-red-500 font-bold">
                      1e-9 is a suitable value.
                    </span>
                  </p>
                ),
              },
            ].map(({ label, name, desc }) => (
              <>
                <div className={`flex items-center`} key={name}>
                  <label className="block text-gray-700 w-fit whitespace-nowrap mr-2 ">
                    {label}
                  </label>
                  <div
                    className={`flex ${
                      desc ? "justify-between" : "justify-end"
                    } gap-2 items-center w-full`}
                  >
                    {desc && <FieldInfo desc={desc} />}
                    <input
                      type="text"
                      name={name}
                      placeholder={`Enter ${label.toLowerCase()}`}
                      className="p-2 border rounded-md w-72"
                      value={params[name as keyof ExecutionParams] || ""}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                {name == "deltaTheta" && (
                  <div className="border-b  w-full mx-auto"></div>
                )}
                {name == "DesignVar_Tolerance_Limit" && (
                  <div className="border-b  w-full mx-auto"></div>
                )}
                {name == "trials" && (
                  <div className="border-b  w-full mx-auto"></div>
                )}
              </>
            ))}

            <div className="flex items-center justify-between ">
              <label className="block text-gray-700 w-1/3 ">
                Type of Optimization
              </label>
              <div className="w-72 flex space-x-4 justify-start gap-5">
                {[
                  {
                    name: "continuous",
                    label: "Continuous",
                    desc: (
                      <div>
                        The lowest limit is{" "}
                        <span className=" text-red-500 font-bold">-1e6.</span>
                        <ol className="list-disc list-inside text-left">
                          <li>
                            Enter mulitple values using &lsquo;,&lsquo; eg.{" "}
                            <span className=" text-green-500 font-bold">
                              3, 4, 2.2, 2
                            </span>
                          </li>
                          <li>
                            Bound count must be equal to the value of Design
                            Variable
                          </li>
                          <li>✅ Global - Single value is allowed</li>
                        </ol>
                      </div>
                    ),
                  },
                  {
                    name: "Discrete",
                    label: "Binary",
                    desc: (
                      <div>
                        The highest limit is{" "}
                        <span className=" text-red-500 font-bold">1e6.</span>
                        <ol className="list-disc list-inside text-left">
                          <li>
                            Enter mulitple values using &lsquo;,&lsquo; eg.{" "}
                            <span className=" text-green-500 font-bold">
                              3, 4, 2.2, 2
                            </span>
                          </li>
                          <li>
                            Bound count must be equal to the value of Design
                            Variable
                          </li>
                          <li>✅ Global - Single value is allowed</li>
                        </ol>
                      </div>
                    ),
                  },
                ].map((type) => (
                  <label
                    className={`flex items-center cursor-pointer `}
                    key={type.name}
                  >
                    <input
                      type="radio"
                      name="typeOfOptimization"
                      value={type.name}
                      checked={params.typeOfOptimization === type.name}
                      onChange={handleRadioChange}
                      className={`mr-2`}
                    />
                    {type.label}
                  </label>
                ))}
              </div>
            </div>

            {params.typeOfOptimization === "continuous" && (
              <>
                {[
                  {
                    name: "lowerBound",
                    label: "Lower Bound",
                    checked: lowerBoundChecked,
                    desc: (
                      <div>
                        The lowest limit is{" "}
                        <span className=" text-red-500 font-bold">-1e6.</span>
                        <ol className="list-disc list-inside text-left">
                          <li>
                            Enter mulitple values using &lsquo;,&lsquo; eg.{" "}
                            <span className=" text-green-500 font-bold">
                              3, 4, 2.2, 2
                            </span>
                          </li>
                          <li>
                            Bound count must be equal to the value of Design
                            Variable
                          </li>
                          <li>✅ Global - Single value is allowed</li>
                        </ol>
                      </div>
                    ),
                  },
                  {
                    name: "upperBound",
                    label: "Upper Bound",
                    checked: upperBoundChecked,
                    desc: (
                      <div>
                        The highest limit is{" "}
                        <span className=" text-red-500 font-bold">1e6.</span>
                        <ol className="list-disc list-inside text-left">
                          <li>
                            Enter mulitple values using &lsquo;,&lsquo; eg.{" "}
                            <span className=" text-green-500 font-bold">
                              3, 4, 2.2, 2
                            </span>
                          </li>
                          <li>
                            Bound count must be equal to the value of Design
                            Variable
                          </li>
                          <li>✅ Global - Single value is allowed</li>
                        </ol>
                      </div>
                    ),
                  },
                ].map(({ name, label, checked, desc }) => (
                  <div className="flex items-center justify-between" key={name}>
                    <label className="block text-gray-70 whitespace-nowrap mr-2">
                      {label}
                    </label>
                    <div
                      className={`flex ${
                        name ? "justify-between" : "justify-end"
                      } gap-2 items-center w-full`}
                    >
                      <FieldInfo desc={desc} />
                      <div className=" flex items-center w-72">
                        <input
                          type="text"
                          name={name}
                          placeholder={`Enter ${label}`}
                          className=" p-2 border rounded-md focus:ring-2 focus:ring-teal-500 focus:border-teal-500"
                          value={params[name as keyof ExecutionParams] || ""}
                          onChange={handleInputChange}
                        />

                        <div className=" flex gap-2 ml-2 select-none justify-center items-center">
                          <input
                            id={name + "Checkbox"}
                            type="checkbox"
                            onChange={handleBoundGlobal}
                            checked={checked}
                            className="focus:outline outline-green-500 h-fit"
                            value={name + "Checkbox"}
                          />
                          <label
                            htmlFor={name + "Checkbox"}
                            className=" cursor-pointer"
                          >
                            Global
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>

          <div className="flex justify-center mt-6">
            <button
              type="button"
              onClick={async () => {
                const formValid = await handleNext();
                if (formValid) {
                  setIndex(2);
                }
              }}
              className={`ml-4 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 p-2 px-4 flex justify-between gap-4 items-center ${
                nextDisabled
                  ? "bg-gray-300 text-gray-500 cursor-not-allowed opacity-55"
                  : "bg-teal-600 hover:bg-teal-700 text-white px-4 py-2 rounded-md mt-4 flex items-center gap-2"
              }`}
              disabled={nextDisabled}
            >
              <div className="flex justify-between gap-4 p-2 px-4 items-center">
                <p>Next</p>
              </div>
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default ExecuteModal;
