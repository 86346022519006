import { AlertCircle } from "lucide-react";

const NoTenant = () => {
  return (
    <div className="flex flex-col items-center justify-center mt-28 bg-gray-50 text-gray-700">
      <AlertCircle className="w-16 h-16 text-red-500 mb-4" />
      <h1 className="text-xl font-semibold mb-2">No Tenant Associated</h1>
      <p className="text-center text-sm">
        You are not associated with any tenant. Please contact the administrator
        for assistance.
      </p>
      <p className="text-center text-sm p-2">
        <a
          href="mailto:support@bosonqpsi.com"
          className="underline text-red-500"
        >
          support@bosonqpsi.com
        </a>
      </p>
    </div>
  );
};

export default NoTenant;
