import { useState } from "react";
import Header from "../../components/common/Header";
import Notes from "../../components/common/Notes";
import Sidebar from "../../components/common/Sidebar";
import ServerTable from "../../components/layout/servers/ServerTable";
import { getTenantList } from "../../services/KeycloakService";
import NoTenant from "./NoTenant";

export interface SidebarProps {
  isOpen: boolean;
}

export interface HeaderProps {
  onToggleSidebar: () => void;
}

export const ServerPage = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const handleToggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  const tenants = getTenantList();
  return (
    <div className="flex">
      <Sidebar isOpen={isSidebarOpen} />

      <div className="flex-1 flex flex-col">
        <Header onToggleSidebar={handleToggleSidebar} />
        {tenants.length === 0 ? <NoTenant/> : <ServerTable />}
      </div>

      <Notes />
    </div>
  );
};

export default ServerPage;
