import React, { useState, useEffect, ChangeEvent } from "react";
import { Trash2, Plus, X } from "lucide-react";

const Notes: React.FC = () => {
  const [notes, setNotes] = useState<string[]>([]);
  const [newNote, setNewNote] = useState<string>("");
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  useEffect(() => {
    const savedNotes = JSON.parse(localStorage.getItem("notes") || "[]");
    setNotes(savedNotes);
  }, []);

  useEffect(() => {
    localStorage.setItem("notes", JSON.stringify(notes));
  }, [notes]);

  const handleAddNote = (): void => {
    if (newNote.trim()) {
      setNotes([...notes, newNote]);
      setNewNote("");
      setIsModalOpen(false);
    }
  };

  const handleDeleteNote = (index: number): void => {
    const updatedNotes = notes.filter((_, i) => i !== index);
    setNotes(updatedNotes);
  };

  const handleDeleteAll = (): void => {
    setNotes([]);
  };

  const toggleModal = (): void => {
    setIsModalOpen(!isModalOpen);
    setNewNote("");
  };

  const handleNoteChange = (e: ChangeEvent<HTMLTextAreaElement>): void => {
    setNewNote(e.target.value);
  };

  return (
    <aside className="w-64 p-4 h-screen bg-white border-l">
      <div className="flex items-center justify-between mb-4">
        <h2 className="font-semibold">Notes</h2>
        <button
          className="p-1.5 bg-teal-500 text-white rounded-full hover:bg-teal-600 flex items-center justify-center"
          onClick={toggleModal}
        >
          <Plus size={14} />
        </button>
      </div>

      <ul className="space-y-2">
        {notes.map((note, index) => (
          <li
            key={index}
            className="group relative p-2 bg-white border rounded-md shadow-sm hover:bg-gray-50 transition-colors duration-200"
          >
            <div className="pr-6 break-words text-gray-700">{note}</div>
            <button
              onClick={() => handleDeleteNote(index)}
              className="absolute top-1/2 right-2 -translate-y-1/2 text-gray-400 opacity-0 group-hover:opacity-100 transition-opacity duration-200 hover:text-red-500 p-1"
            >
              <Trash2 size={14} />
            </button>
          </li>
        ))}
      </ul>

      {notes.length > 0 && (
        <button
          onClick={handleDeleteAll}
          className="mt-4 p-2 w-full bg-red-100 text-red-600 rounded-md hover:bg-red-200 transition-colors duration-200 text-sm font-medium flex items-center justify-center gap-2"
        >
          <Trash2 size={14} />
          Delete All
        </button>
      )}

      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-80 relative">
            <button
              onClick={toggleModal}
              className="absolute top-4 right-4 text-gray-400 hover:text-gray-600"
            >
              <X size={20} />
            </button>

            <h3 className="text-lg font-semibold mb-4">Add a New Note</h3>
            <textarea
              value={newNote}
              onChange={handleNoteChange}
              placeholder="Type your note here..."
              className="w-full p-2 border rounded-md mb-4 text-gray-700 min-h-[100px] resize-none focus:outline-none focus:ring-2 focus:ring-teal-500 focus:border-transparent"
            />
            <div className="flex justify-end space-x-2">
              <button
                onClick={toggleModal}
                className="px-4 py-2 text-gray-600 hover:text-gray-800 font-medium"
              >
                Cancel
              </button>
              <button
                onClick={handleAddNote}
                className="px-4 py-2 bg-teal-500 text-white rounded-md hover:bg-teal-600 font-medium transition-colors duration-200"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}
    </aside>
  );
};

export default Notes;
