import { ArrowBigUpDash, Loader } from "lucide-react";
import React, { useEffect, useState } from "react";
import { toast, Toaster } from "sonner";
import backendAPI from "../../../services/apiRequestService";
import { EvalutorComponentProps } from "../../../types/types";

const EvalutorComponent: React.FC<EvalutorComponentProps & { payload: any }> = ({
  project,
  onClose,
  setIndex,
  payload,
}) => {
  const [evaluatorArguments, setEvaluatorArguments] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (payload) {
      const combinedPayload = {
        ...payload,
        evaluatorParams: { arguments: {} },
      };
    }
  }, [payload]);

  const handleJsonFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        try {
          const jsonContent = JSON.parse(event.target?.result as string);
          const wrappedContent = { arguments: jsonContent };
          setEvaluatorArguments(JSON.stringify(wrappedContent, null, 2));
        } catch (error) {
          toast.error("Invalid JSON file");
        }
      };
      reader.readAsText(file);
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    let evaluatorArgs;
    try {
      evaluatorArgs = evaluatorArguments ? JSON.parse(evaluatorArguments) : {};
    } catch {
      toast.error("Invalid JSON in evaluator arguments");
      return;
    }
    if (!evaluatorArgs.arguments) {
      evaluatorArgs = { arguments: evaluatorArgs };
    }

    setIsSubmitting(true);

    try {
      const finalPayload = { ...payload, evaluatorParams: evaluatorArgs };

      await backendAPI.post(
        `/project-management/projects/${project.id}/executions`,
        finalPayload,
        { headers: { "Content-Type": "application/json" } }
      );
      toast.success("Execution submitted successfully!");
      onClose();
    } catch {
      toast.error("Failed to submit execution.");
    } finally {
      localStorage.removeItem("selectedServer");
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <Toaster richColors expand={true} />
      {/* Header */}
      <div className="p-6 border-b flex justify-between items-center">
        <button
          type="button"
          onClick={() => setIndex(1)}
          className="text-gray-500 hover:text-gray-700 text-2xl mr-5"
        >
          ←
        </button>
        <h2 className="text-xl font-bold flex-1">Execute {project.name}</h2>
        <button
          type="button"
          onClick={onClose}
          className="text-gray-500 hover:text-gray-700 text-2xl"
        >
          ×
        </button>
      </div>
      {/* Form */}
      <div className="p-6">
        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="space-y-4">
            <div className="flex items-center justify-between">
              <label className="block text-gray-700">Evaluator Arguments</label>
              <input
                type="file"
                accept=".json"
                onChange={handleJsonFileUpload}
                className="hidden"
                id="json-upload"
              />
              <label
                htmlFor="json-upload"
                className="bg-gray-100 hover:bg-gray-200 text-gray-700 px-4 py-2 rounded-md cursor-pointer"
              >
                Upload JSON
              </label>
            </div>
            <textarea
              className="w-full h-32 p-2 border rounded-md font-mono text-sm focus:ring-2 focus:ring-teal-500 focus:border-teal-500 mt-2"
              onChange={(e) => setEvaluatorArguments(e.target.value)}
              value={evaluatorArguments}
              placeholder='Enter evaluator arguments in JSON format, e.g.:                                   
{
  "dimensionality": 2,
  "foo": "bar"
}'
            />
          </div>
          <div className="flex justify-center">
            <button
              type="submit"
              disabled={isSubmitting}
              className={`bg-teal-500 text-white rounded-md ${
                isSubmitting
                  ? "cursor-not-allowed opacity-50"
                  : "hover:bg-teal-600 focus:ring-2 focus:ring-teal-500"
              }`}
            >
              <div className="flex items-center gap-2 px-4 py-2">
                {isSubmitting ? (
                  <Loader className="animate-spin" />
                ) : (
                  <ArrowBigUpDash />
                )}
                <p>Submit</p>
              </div>
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default EvalutorComponent;
