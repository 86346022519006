import axios, { AxiosInstance } from "axios";
import Keycloak from "keycloak-js";
import {
  frontendUrl,
  keycloakClientId,
  keycloakRealm,
  keycloakUrl,
} from "../routes/BaseUrl";
import { toast } from "sonner";

// Enhanced interface for better type safety
interface KeycloakTokenParsed {
  [key: string]: any;
  sub?: string;
  tenant_roles?: Array<Record<string, unknown>>;
}

let kc: Keycloak.KeycloakInstance | undefined;

const initOptions = {
  url: keycloakUrl as string,
  realm: keycloakRealm as string,
  clientId: keycloakClientId as string,
};

const httpClient: AxiosInstance = axios.create({
  baseURL: process.env.BASE_URL,
});

export const keyInit = (): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    if (!keycloakUrl || !keycloakRealm || !keycloakClientId) {
      reject(new Error("Keycloak configuration is incomplete"));
      return;
    }

    try {
      kc = new Keycloak(initOptions);

      kc.init({
        onLoad: "login-required",
        checkLoginIframe: true,
        pkceMethod: "S256",
      })
        .then((auth: boolean) => {
          if (!auth) {
            window.location.reload();
            resolve(false);
            return;
          }

          const token = kc?.token;
          if (token) {
            localStorage.setItem("token", token);
            document.cookie = `jwt=${token}; path=/;`;
            httpClient.defaults.headers.common["Authorization"] =
              `Bearer ${token}`;
          }

          if (kc) {
            const keycloakInstance = kc;
            keycloakInstance.onTokenExpired = () => {
              alert("Session Expired. Login Again.");
              keycloakInstance.logout({ redirectUri: frontendUrl });
            };
          }

          resolve(true);
        })
        .catch((error) => {
          console.error("Keycloak initialization error", error);
          reject(
            new Error(error instanceof Error ? error.message : "Unknown error")
          );
        });
    } catch (error) {
      console.error("Keycloak setup error", error);
      reject(error instanceof Error ? error : new Error("Unknown error"));
    }
  });
};

export const getkc = (): Keycloak.KeycloakInstance | undefined => {
  return kc;
};

export const getUserInfo = (): KeycloakTokenParsed | undefined => {
  return kc?.tokenParsed;
};

export const getUserAccess = (tenant:string): any => {
  const info = getUserInfo();
  try{
    const accessObj = info?.tenant_roles
    const list = accessObj?.find(obj => obj[tenant])?.[tenant];
    return list;
  } catch (error) {
    console.error("Roles are not set Correctly in Keycloak");
    return [];
  }
};

export const getUserId = (): string => {
  if (!kc?.tokenParsed?.sub) {
    throw new Error("Unable to retrieve User ID");
  }
  return kc.tokenParsed.sub;
};

export const getTenantList = (): string[] => {
  const userAccessList = kc?.tokenParsed?.tenant_roles;

  if (!userAccessList || !Array.isArray(userAccessList)) {
    return [];
  }

  if (userAccessList.length === 0) {
    return [];
  }

  const list = userAccessList.length > 0 ? userAccessList : [userAccessList[0]];

  return list.reduce<string[]>((acc, item) => {
    if (item && typeof item === "object") {
      return [...acc, ...Object.keys(item)];
    }
    return acc;
  }, []);
};

export const logoutUser = (): void => {
  kc?.logout({ redirectUri: frontendUrl });
};

export default httpClient;
