import React from "react";
import { getDateFromTimestamp } from "../../common/DateAndTime";

interface Evaluator {
  id: string;
  name: string;
  functionName: string;
  description: string;
  language: "PYTHON" | "MATLAB" | "CPP";
  fileName: string;
  status: string;
  createdAt: string;
}

interface ViewEvaluatorDetailsProps {
  evaluator: Evaluator;
  onClose: () => void;
}

interface DetailItem {
  label: string;
  value: string | undefined;
}

const formatText = (text: string | undefined): string => {
  if (!text) return "";
  if (text === "PYTHON") return "Python";
  if (text === "MATLAB") return "MATLAB";
  if (text === "CPP") return "CPP";
  return text
};

const ViewEvaluatorDetails: React.FC<ViewEvaluatorDetailsProps> = ({
  evaluator,
  onClose,
}) => {
  if (!evaluator) return null;

  const details: DetailItem[] = [
    { label: "Name", value: evaluator.name },
    { label: "Function Name", value: evaluator.functionName },
    { label: "Description", value: evaluator.description },
    { label: "Language", value: evaluator.language },
    { label: "File Name", value: evaluator.fileName },
    { label: "Status", value: evaluator.status },
    {
      label: "Created At",
      value: getDateFromTimestamp(parseInt(evaluator.createdAt), true),
    },
  ];

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
      <div className="bg-white rounded-lg p-6 w-full max-w-2xl">
        <h2 className="text-lg font-medium mb-4 border-b pb-2">
          Evaluator Details
        </h2>

        <div className="space-y-4">
          {details.map((detail, index) => (
            <div key={index} className="flex items-center">
              <div className="w-1/3 font-medium text-gray-700">
                {detail.label}
              </div>
              <div className="w-2/3 p-2 border rounded-md bg-gray-50 text-gray-800">
                {formatText(detail.value)}&nbsp;
              </div>
            </div>
          ))}
        </div>

        <div className="pt-4 mt-4 border-t flex justify-end">
          <button
            onClick={onClose}
            className="px-4 py-1.5 rounded-md bg-teal-600 text-white hover:bg-teal-700"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default ViewEvaluatorDetails;
