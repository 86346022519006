import { AxiosError } from "axios";
import React, { useEffect, useState } from "react";
import { toast, Toaster } from "sonner";
import Table from "../../common/Table";
import ManageUsersDetails from "./ManageUsersDetails";
import ManageUsersModal from "./ManageUsersModal";
import { useAppSelector } from "../../../redux/hooks";
import ThreeDot from "../../common/ThreeDot";

interface User {
  id: string;
  username: string;
  firstName: string;
  lastName: string;
  email: string;
  role: string;
}

const ManageUsersTable: React.FC = () => {
  const [users, setUsers] = useState<User[]>([
    {
      id: "1",
      username: "john_doe",
      firstName: "John",
      lastName: "Doe",
      email: "john@example.com",
      role: "Admin",
    },
    {
      id: "2",
      username: "jane_smith",
      firstName: "Jane",
      lastName: "Smith",
      email: "jane@example.com",
      role: "User",
    },
  ]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [dropdownOpen, setDropdownOpen] = useState<number | null>(null);
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [deletingUser, setDeletingUser] = useState<User | null>(null);
  
  const activeTenantId = useAppSelector((state) => state.tenant.active);

  const [menuState, setMenuState] = useState({
    visible: { view: true, delete: true, download: true },
    processing: { view: false, delete: false, download: false },
    disabled: { view: false, delete: false, download: false },
  });
  
  const handleAction = (action: any) => {
    // handle user's menu actions
  };


  const fetchUsers = async (): Promise<void> => {
    try {
      // const response = await backendAPI.get("/users");
      // setUsers(response.data);
    } catch (error) {
      setUsers([]);
      if (error instanceof AxiosError && error.response) {
        toast.error(
          error.response?.data?.error ||
            "An error occurred while fetching data."
        );
      } else {
        toast.error("An unknown error occurred. Please try again later.");
      }
    }
  };

  useEffect(() => {
    fetchUsers();
  }, [activeTenantId]);

  const handleAddUser = (): void => {
    setShowModal(true);
  };

  const handleRegisterUser = async (formData: FormData): Promise<void> => {
    try {
      const newUser = {
        id: Math.random().toString(),
        username: formData.get("username") as string,
        firstName: formData.get("firstName") as string,
        lastName: formData.get("lastName") as string,
        email: formData.get("email") as string,
        role: formData.get("role") as string,
      };

      setUsers((prevUsers) => [...prevUsers, newUser]);
      toast.success("User Added Successfully.");
      setShowModal(false);
    } catch (error) {
      if (error instanceof AxiosError && error.response) {
        toast.error(
          error.response?.data?.message || "Unable to create User now."
        );
      } else {
        toast.error("An unknown error occurred while adding the user.");
      }
    }
  };

  const handleView = (user: User): void => {
    setSelectedUser(user);
    setDropdownOpen(null);
  };

  const handleDeleteClick = (user: User): void => {
    setDeletingUser(user);
    setShowConfirmation(true);
  };

  const handleDelete = async (): Promise<void> => {
    if (!deletingUser) return;

    try {
      // In real app, this would be an API call
      setUsers((prevUsers) =>
        prevUsers.filter((user) => user.id !== deletingUser.id)
      );
      toast.success("User deleted successfully.");
    } catch (error) {
      if (error instanceof AxiosError && error.response) {
        toast.error(error.response?.data?.message || "Error deleting the User");
      } else {
        toast.error("An unknown error occurred while deleting the user.");
      }
    } finally {
      setShowConfirmation(false);
      setDeletingUser(null);
    }
  };

  const handleCancelDelete = (): void => {
    setShowConfirmation(false);
    setDeletingUser(null);
  };

  const columns = React.useMemo(
    () => [
      {
        header: "Username",
        accessor: "username" as const,
      },
      {
        header: "First Name",
        accessor: "firstName" as const,
      },
      {
        header: "Last Name",
        accessor: "lastName" as const,
      },
      {
        header: "Email",
        accessor: "email" as const,
      },
      {
        header: "Role",
        accessor: "role" as const,
      },
      {
        header: "Actions",
        accessor: "actions" as const,
        render: (user: User, index: number) => (
          <ThreeDot
          visible={menuState.visible}
          processing={menuState.processing}
          disabled={menuState.disabled}
          onAction={handleAction}
          on={user}
          isOpen={dropdownOpen === index}
          toggleDropdown={(event:any) => {
            if (event === null) {
              setDropdownOpen(null);
              return;
            }
            event.stopPropagation();
            setDropdownOpen(dropdownOpen === index ? null : index);
          }}
        />
        ),
      },
    ],
    [dropdownOpen, handleView, handleDeleteClick]
  );

  return (
    <div className="pt-4 shadow-inner">
      <Toaster richColors />
      <div className="flex justify-between items-center p-4">
        <h2 className="text-lg font-bold">Total Users - {users.length}</h2>
        <button
          className="bg-black text-white px-4 py-2 rounded-md flex items-center"
          onClick={handleAddUser}
        >
          + Add User
        </button>
      </div>

      <Table
        data={users}
        columns={columns}
        onRowClick={() => setDropdownOpen(null)}
        striped={true}
        className="w-full"
      />

      {showModal && (
        <ManageUsersModal
          onClose={() => setShowModal(false)}
          onRegister={handleRegisterUser}
        />
      )}

      {selectedUser && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <ManageUsersDetails
            user={selectedUser}
            onClose={() => setSelectedUser(null)}
          />
        </div>
      )}

      {showConfirmation && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-30">
          <div className="bg-white p-6 rounded-md shadow-lg w-96">
            <div className="border-b pb-4 mb-4">
              <h3 className="text-lg font-semibold text-center">
                Are you sure you want to delete?
              </h3>
            </div>
            <div className="flex justify-around pt-4 mt-4">
              <button
                className="bg-teal-600 hover:bg-gray-600 text-white px-4 py-2 rounded-md"
                onClick={handleDelete}
              >
                Yes
              </button>
              <button
                className="bg-teal-600 hover:bg-gray-600 text-white px-4 py-2 rounded-md"
                onClick={handleCancelDelete}
              >
                No
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ManageUsersTable;
