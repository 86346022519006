import { X } from "lucide-react";
import React, { useState } from "react";
import InputField from "../servers/components/InputField";

export interface ManageUsersModalProps {
  onClose: () => void;
  onRegister: (formData: FormData) => Promise<void>;
}

const ManageUsersModal: React.FC<ManageUsersModalProps> = ({
  onClose,
  onRegister,
}) => {
  const [username, setUsername] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [tenant, setTenant] = useState<string>("");
  const [role, setRole] = useState<string>("");

  const roles = ["Admin", "User", "Manager", "Viewer"];

  const handleSubmit = async (
    e: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("username", username);
      formData.append("firstName", firstName);
      formData.append("lastName", lastName);
      formData.append("email", email);
      formData.append("tenant", tenant);
      formData.append("role", role);

      await onRegister(formData);
    } catch (error) {
      console.error(error);
      alert(
        error instanceof Error ? error.message : "An unexpected error occurred."
      );
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
      <div className="bg-white rounded-lg p-6 w-full max-w-2xl">
        <div className="bg-white pb-2 mb-2 border-b flex justify-between items-center">
          <h2 className="text-lg font-medium">Add New User</h2>
          <button
            onClick={onClose}
            className="p-1 hover:bg-gray-100 rounded-full transition-colors"
            aria-label="Close"
          >
            <X className="h-5 w-5 text-gray-500" />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="space-y-4 pt-2">
          <InputField
            id="username"
            label="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
          <InputField
            id="firstName"
            label="First Name"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            required
          />
          <InputField
            id="lastName"
            label="Last Name"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            required
          />
          <InputField
            id="email"
            label="Email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <InputField
            id="tenant"
            label="Tenant"
            value={tenant}
            onChange={(e) => setTenant(e.target.value)}
            required
          />

          <div className="space-y-1">
            <label htmlFor="role" className="block font-medium">
              Role
            </label>
            <select
              id="role"
              value={role}
              onChange={(e) => setRole(e.target.value)}
              className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-teal-500"
              required
            >
              <option value="">Select a role</option>
              {roles.map((r) => (
                <option key={r} value={r}>
                  {r}
                </option>
              ))}
            </select>
          </div>

          <div className="pt-2 mt-2 border-t bg-white flex justify-end">
            <button
              type="button"
              onClick={onClose}
              className="mr-2 px-4 py-1.5 rounded-md bg-gray-300 hover:bg-gray-400"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-1.5 rounded-md bg-teal-600 text-white hover:bg-teal-700"
            >
              Add User
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ManageUsersModal;
