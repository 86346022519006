import React, { useState, useEffect } from "react";
import { Menu, LogOut, User } from "lucide-react";
import {
  getTenantList,
  getUserInfo,
  logoutUser,
} from "../../services/KeycloakService";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { updateActiveTenant } from "../../redux/tenantSlice";

interface HeaderProps {
  onToggleSidebar: () => void;
}

const Header: React.FC<HeaderProps> = ({ onToggleSidebar }) => {
  const [tenants, setTenants] = useState<any>([]);
  const dispatch = useAppDispatch();

  const activeTenant = useAppSelector((state) => state.tenant.active);
  const logout = () => {
    logoutUser();
    localStorage.clear();
  };

  useEffect(() => {
    const tenants = getTenantList();
    setTenants(tenants);
    if (activeTenant === "" && tenants.length > 0) {
      dispatch(updateActiveTenant(tenants[0]));
    }
  }, [activeTenant, dispatch]);

  const info = getUserInfo();
  const tenantOption = (e: any) => {
    dispatch(updateActiveTenant(e.target.value));
  };

  return (
    <header className="flex justify-between items-center p-4 bg-white border-b">
      {/* Sidebar toggle */}
      <button
        onClick={onToggleSidebar}
        className="p-2 hover:bg-gray-100 rounded-md transition-colors"
      >
        <Menu className="w-6 h-6 text-gray-700" />
      </button>

      {/* User info and tenant selection */}
      <div className="flex items-center gap-3 text-gray-700">
        <User className="w-5 h-5" />
        <span className="font-semibold text-lg">
          {info?.preferred_username}
        </span>

        {tenants.length ? (
          <div className="flex items-center gap-2">
            <span>/</span>
            <select
              onChange={tenantOption}
              value={activeTenant}
              className="p-1 pr-2 outline-none text-md border-b-2 border-transparent hover:border-indigo-600 rounded-md cursor-pointer bg-transparent"
            >
              {tenants.map((item: string) => (
                <option key={item} value={item}>
                  {item}
                </option>
              ))}
            </select>
          </div>
        ) : (
          <p className="text-sm mt-1 text-red-500">
            <span className="font-bold">No Tenant(s)</span>{" "}
          </p>
        )}
      </div>

      {/* Logout button */}
      <button
        onClick={logout}
        className="flex items-center gap-2 p-2 rounded-md hover:text-indigo-600 hover:bg-indigo-100 transition-colors"
      >
        <LogOut className="w-5 h-5" /> Logout
      </button>
    </header>
  );
};

export default Header;
