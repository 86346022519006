import { AxiosError } from "axios";
import { ArrowBigUpDash, FileText, Info, Loader, Upload, X } from "lucide-react";
import React, { useState } from "react";
import { toast } from "sonner";
import backendAPI from "../../../services/apiRequestService";
import ToggleBtn from "../../common/button/ToggleBtn";

interface Evaluator {
  id: string;
  name: string;
  language: "PYTHON" | "MATLAB" | "CPP";
  createdAt: string;
  deleted?: boolean;
  description?: string;
  functionName?: string;
}

export interface EvaluatorModalProps {
  onClose: () => void;
  onRegister: any;
}

const EvaluatorModal: React.FC<EvaluatorModalProps> = ({
  onClose,
  onRegister,
}) => {
  const DESC_LIMIT = 250;
  const [name, setName] = useState<string>("");
  const [functionName, setFunctionName] = useState<string>("");
  const [language, setLanguage] = useState<"PYTHON" | "MATLAB" | "CPP">("PYTHON");
  const [requireFile, setRequireFile] = useState<boolean>(false);
  const [file, setFile] = useState<File | null>(null);
  const [description, setDescription] = useState<string>("");
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const getUploadText = (): string => {
    if (language === "PYTHON") {
      return "Upload your Python evaluator [.py]";
    } else if (language === "MATLAB") {
      return "Upload your MATLAB evaluator [.m]";
    } else if (language === "CPP") {
      return "Upload your CPP evaluator [.cpp]";
    }
    return "Python or MATLAB files only";
  };

  const handleSubmit = async (
    e: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const evaluatorPayload = {
        name,
        language,
        description,
        requireFile,
        functionName,
      };
      if (!file) {
        toast.error("need file for registration.");
        return;
      }
      const evaluatorResponse = await backendAPI.post(
        "/project-management/evaluators",
        evaluatorPayload
      );
      const evaluatorId = evaluatorResponse.data.id;

      const formData = new FormData();
      formData.append("evaluatorFile", file);

      await backendAPI.post(
        `/project-management/evaluators/${evaluatorId}`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      toast.success("Evaluator registered successfully.");
      onRegister(evaluatorResponse.data);
    } catch (error) {
      if (error instanceof AxiosError && error.response) {
        toast.error(
          error.response?.data?.message || error.response?.data?.error || "Unable to register evaluator."
        );
      } else {
        toast.error("An unknown error occurred.");
      }
    } finally {
      setIsSubmitting(false);
      onClose()
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const selectedFile = e.target.files?.[0];
    if (selectedFile) {
      setFile(selectedFile);
    }
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>): void => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>): void => {
    e.preventDefault();
    setIsDragging(false);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>): void => {
    e.preventDefault();
    setIsDragging(false);
    const droppedFile = e.dataTransfer.files[0];
    if (droppedFile) {
      setFile(droppedFile);
    }
  };

  const removeFile = (): void => {
    setFile(null);
  };

  return (
    <div className="fixed backdrop-blur-sm bg-opacity-50 inset-0 bg-black flex items-center justify-center z-50 p-4">
      <div className="bg-white shadow-lg bg-opacity-95 backdrop-blur-sm rounded-lg p-6 w-full max-w-2xl">
        <div className="pb-2 mb-2 border-b flex justify-between items-center">
          <h2 className="text-lg font-medium">Register Evaluator</h2>
          <button
            onClick={onClose}
            className="p-1 hover:bg-gray-100 rounded-full transition-colors"
            aria-label="Close"
          >
            <X className="h-5 w-5 text-gray-500" />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="space-y-4 pt-2">
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label htmlFor="name" className="block font-medium mb-1">
                Name
              </label>
              <input
                type="text"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="border rounded-md px-3 py-1.5 w-full shadow-inner"
                required
              />
            </div>
            <div>
              <label htmlFor="function-name" className="block font-medium mb-1">
                Function Name
              </label>
              <input
                type="text"
                id="function-name"
                value={functionName}
                onChange={(e) => setFunctionName(e.target.value)}
                className="border rounded-md px-3 py-1.5 w-full shadow-inner"
                required
              />
            </div>
          </div>
          <div className=" flex gap-4 justify-between ">
            <div className="">
              <label htmlFor="language" className="block font-medium mb-1">
                Language
              </label>
              <div className="flex items-end gap-4">
                <label className="">
                  <input
                    type="radio"
                    name="language"
                    value="PYTHON"
                    checked={language === "PYTHON"}
                    onChange={(e) =>
                      setLanguage(e.target.value as "PYTHON" | "MATLAB" | "CPP")
                    }
                    className="mr-4"
                  />
                  Python
                </label>
                <label className="">
                  <input
                    type="radio"
                    name="language"
                    value="CPP"
                    checked={language === "CPP"}
                    onChange={(e) =>
                      setLanguage(e.target.value as "PYTHON" | "MATLAB" | "CPP")
                    }
                    className=" mr-4 group-hover:opacity-100 "
                  />
                  C++
                </label>
                <div className=" relative group">
                  <div className=" absolute -top-14 w-fit z-10 text-sm opacity-0 transition-opacity group-hover:opacity-100 bg-opacity-20 backdrop-blur-md p-2 pointer-events-none bg-slate-300 rounded-md shadow-md text-center text-gray-500">
                    Available Soon!
                  </div>
                  <label className=" cursor-not-allowed opacity-50">
                    <input
                      type="radio"
                      name="language"
                      value="MATLAB"
                      checked={language === "MATLAB"}
                      onChange={(e) =>
                        setLanguage(e.target.value as "PYTHON" | "MATLAB" | "CPP")
                      }
                      className=" mr-4 group-hover:opacity-100 "
                      disabled
                    />
                    MATLAB
                  </label>
                </div>
              </div>
            </div>
            <hr/>
            <div className=" flex gap-1 flex-1">
              <label htmlFor="language" className="font-medium flex flex-col gap-2">
                <div className=" relative group flex items-center">
                <p>Require File</p>
                  <div className=" absolute bottom-5 right-4 w-52 z-10 text-sm font-normal opacity-0 transition-opacity group-hover:opacity-100 bg-opacity-20 backdrop-blur-md p-2 pointer-events-none bg-slate-300 rounded-md shadow-md text-center text-gray-800">
                  Enabling this will allow file upload during project creation with this evaluator.
                  </div>
                  <Info size={15} className="p-px ml-2"/>
                </div>
              <ToggleBtn enabled={requireFile} onChange={setRequireFile}/>
              </label>
            </div>
          </div>

          <div className="mb-2 relative">
            <label htmlFor="description" className="block font-medium mb-1">
              Description
            </label>
            <textarea
              id="description"
              value={description}
              maxLength={DESC_LIMIT}
              onChange={(e) => setDescription(e.target.value)}
              className="border rounded-md px-3 py-1.5 w-full h-16 outline-none text-sm shadow-inner"
              required
            />
            <p className="absolute right-1 -bottom-4 text-xs leading-5 text-gray-600">{`${description.length} / ${DESC_LIMIT}`}</p>
          </div>

          <div className="mb-2">
            <label className="block font-medium mb-1">Upload File</label>
            <div
              className={`border-2 border-dashed rounded-lg p-4 transition-colors ${isDragging ? "border-blue-500 bg-teal-50" : "border-gray-300"
                }`}
              onDragOver={handleDragOver}
              onDragLeave={handleDragLeave}
              onDrop={handleDrop}
            >
              {!file ? (
                <div className="text-center">
                  <Upload className="mx-auto h-8 w-8 text-gray-400" />
                  <div className="mt-2 flex text-sm leading-6 text-gray-600 justify-center">
                    <label
                      htmlFor="file-upload"
                      className="relative cursor-pointer rounded-md font-semibold text-teal-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-teal-600 focus-within:ring-offset-2 hover:text-teal-500"
                    >
                      <span>Click to upload</span>
                      <input
                        id="file-upload"
                        name="file-upload"
                        type="file"
                        className="sr-only"
                        onChange={handleFileChange}
                      />
                    </label>
                    <p className="pl-1">or drag and drop</p>
                  </div>
                  <p className="text-xs leading-5 text-gray-600">
                    {getUploadText()}
                  </p>
                </div>
              ) : (
                <div className="flex items-center justify-between bg-gray-50 p-2 rounded-md">
                  <div className="flex items-center">
                    <FileText className="h-5 w-5 text-blue-500 mr-2" />
                    <span className="text-sm text-gray-600">{file.name}</span>
                  </div>
                  <button
                    type="button"
                    onClick={removeFile}
                    className="text-red-500 hover:text-red-700"
                  >
                    <X className="h-4 w-4" />
                  </button>
                </div>
              )}
            </div>
          </div>

          <div className="pt-2 mt-2 border-t flex justify-end">
            <button
              type="submit"
              disabled={isSubmitting}
              className={` ${isSubmitting ? "opacity-50 cursor-not-allowed" : ""} px-3 py-1.5 flex gap-3 rounded-md bg-teal-600 text-white hover:bg-teal-700`}
            >
              {isSubmitting ? (
                <Loader className="animate-spin" />
              ) : (
                <ArrowBigUpDash />
              )}
              <p>Register Evaluator</p>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EvaluatorModal;
