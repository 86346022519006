import axios from 'axios';
import { getkc, getUserId } from './KeycloakService';  
import { backendUrl } from '../routes/BaseUrl';
import { store } from '../store';

const backendAPI = axios.create({
   baseURL: backendUrl,
});

backendAPI.interceptors.request.use(config => {
   const accessToken = getkc()?.token;
   const state = store.getState(); 
   const tenantId = state.tenant.active;

   config.headers.Authorization = `Bearer ${accessToken}`;
   config.headers.userId = getUserId();
   config.headers.tenantId = tenantId;
   return config;
});

export default backendAPI;
